.s2l-academy-ourprojects {
    padding: 20px 60px 40px 60px;

    .ourprojects-conatiner {
        h2 {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
            margin-top: 0px;
        }

        .about-our-projects-list {
            .projects-item-conatiner {
                border: 1px solid #d0d3ea;

                .projects-item {
                    display: flex !important;
                    flex-direction: row;
                    gap: 50px;
                    width: 100%;

                    .aboutus-project-logo {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 20%;

                        img {
                            object-fit: contain;
                        }
                    }

                    .project-content {
                        width: 80%;

                        h3 {
                            font-size: 24px;
                            line-height: 1.4;
                            font-weight: 700;
                        }

                        p {
                            font-size: 20px;
                            line-height: 1.6;
                        }
                    }
                }

                .read-more {
                    text-align: end;
                }
            }

            .slick-slide {
                padding: 0px !important;

                .px-2 {
                    padding: 0px !important;
                }
            }

            .slick-prev {
                display: none !important;
            }

            .slick-next {
                display: none !important;
            }

            .slick-dots li button:before {
                font-size: 20px !important;
                color: #7b9d50 !important;
            }

            .slick-dots li.slick-active button:before {
                font-size: 20px !important;
                color: #7b9d50 !important;
            }

            .slick-dots {
                bottom: -50px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-ourprojects {
        padding: 20px 40px;

        .ourprojects-conatiner .about-our-projects-list .projects-item-conatiner {
            .projects-item {
                .aboutus-project-logo {
                    width: 25% !important;
                }

                .project-content {
                    width: 75% !important;
                }
            }
        }
    }


}

@media screen and (max-width: 920px) {
    .s2l-academy-ourprojects {
        .ourprojects-conatiner {
            h2 {
                font-size: 32px;
                line-height: 1.2;
            }

            .about-our-projects-list {
                .projects-item-conatiner {
                    border: 1px solid #d0d3ea;

                    .projects-item {
                        flex-direction: column !important;
                        gap: 20px;

                        .aboutus-project-logo {
                            width: 100% !important;

                            img {
                                object-fit: contain;
                                height: 200px;
                                width: 210px;
                            }
                        }

                        .project-content {
                            width: 100% !important;

                            h3 {
                                font-size: 24px;
                                line-height: 1.4;
                            }

                            p {
                                font-size: 20px;
                                line-height: 1.6;
                                text-align: left !important;

                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .s2l-academy-ourprojects {
        padding: 20px 20px;

        .ourprojects-conatiner {
            h2 {
                font-size: 28px;
                line-height: 1.2;
            }

            .about-our-projects-list {
                .projects-item-conatiner {
                    .projects-item {
                        flex-direction: column !important;
                        gap: 30px;
                        padding: 1rem !important;

                        .aboutus-project-logo img {
                            height: 150px;
                        }


                        .project-content {

                            h3 {
                                font-size: 22px;
                                line-height: 1.4;
                            }

                            p {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .read-more {
                    text-align: center !important;
                    padding: 1rem !important;
                }
            }
        }
    }
}