.s2l-academy-students {
    padding: 0px 60px 60px 60px;

    .students-container {
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
            text-align: center;
        }

        p {
            color: #433f3e !important;
            font-size: 20px;
            line-height: 1.6;
        }

        .students-data {
            .sm-screen-carousel {
                display: none !important;
            }

            .academy-student-profile .student-profile-card {
                .stud-profile-pic {
                    width: 100%;
                    position: relative;

                    .student{
                        width: 100%;
                        border-radius: 10px;
                        clip-path: fill-box;
                    }

                    .annotation {
                        position: absolute;
                        bottom: -24px;
                        right: 5px;
                    }
                }

                .student-profile-card-content {
                    h3 {
                        color: #000000;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 1;
                    }

                    p {
                        color: #433f3e;
                        font-size: 20px;
                    }
                }
            }

            .p-carousel-prev {
                display: none !important;
            }

            .p-carousel-next {
                display: none !important;
            }

            .p-carousel-indicators {
                display: none !important;
            }
        }

        .carousel-navigatoion {
            .cursor-nav {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 1.2rem;
                    color: #ffffff;
                }
            }

            .cursor-nav.disabled {
                cursor: not-allowed !important;
                background-color: #ccc;
                border-radius: 50%;
            }

            .cursor-nav.active {
                border-radius: 50%;
                background-color: #8daf61;

                i {
                    font-size: 22px !important;
                    color: #ffffff !important;
                }


                &:hover {
                    transform: scale(1.1);
                }


            }

            .cursor-nav-sm {
                display: none !important;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .s2l-academy-students {
        padding: 0px 50px 50px 50px;

        .students-container .students-data .academy-student-profile .student-profile-card {
            
            .student-profile-card-content .student-achievements {
                min-height: 50px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-students {
        padding: 0px 50px 50px 50px;
    }
}

@media screen and (max-width: 1024px) {
    .s2l-academy-students {
        padding: 0px 50px 50px 50px !important;

        .students-container {
            .students-data {
                .product-container{
                    margin: 0rem !important;
                }
                .lg-screen-carousel {
                    display: none !important;
                }

                .sm-screen-carousel {
                    display: block !important;
                }

                .academy-student-profile .student-profile-card .stud-profile-pic {
                    width: 100% !important;
                }
            }

            .carousel-navigatoion {
                .cursor-nav-sm {
                    height: 40px;
                    width: 40px;
                    display: flex !important; 
                    align-items: center;
                    justify-content: center;
    
                    i {
                        font-size: 1.2rem;
                        color: #ffffff;
                    }
                }
    
                .cursor-nav-sm.disabled {
                    cursor: not-allowed !important;
                    background-color: #ccc;
                    border-radius: 50%;
                }
    
                .cursor-nav-sm.active {
                    border-radius: 50%;
                    background-color: #8daf61;
    
                    i {
                        font-size: 22px !important;
                        color: #ffffff !important;
                    }
    
    
                    &:hover {
                        transform: scale(1.1);
                    }
    
    
                }
    
                .cursor-nav-lg {
                    display: none !important;
                }
            }

        }
    }
}


@media screen and (max-width: 768px) {
    .s2l-academy-students {
        padding: 0px 30px 50px 30px !important;
    }
    
}

@media screen and (max-width: 575px) {
    .s2l-academy-students {
        padding: 15px !important;

        .students-container {
            h2 {
                font-size: 26px;
                line-height: 1.2;
            }

            p {
                font-size: 18px;
            }

            .students-data {
                
                .lg-screen-carousel {
                    display: none !important;
                }

                .sm-screen-carousel {
                    display: block !important;
                }

                .academy-student-profile .student-profile-card {
                    .stud-profile-pic {
                        width: 100%;
                    }

                    .student-profile-card-content {
                        h3 {
                            font-size: 20px;
                        }

                        p {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-students {

        .students-container {
            margin-top: 30px !important;
            .students-data {
                margin-top: 2rem !important;
            }
        }
    }
}