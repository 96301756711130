.s2l-academy-features {
    padding: 60px;

    .feature-container {
        .feature-header {
            h2 {
                font-size: 32px;
                font-weight: 700;
                line-height: 1.5;
            }

            p {
                color: #433f3e;
                font-size: 20px;
                line-height: 1.6;
            }
        }

        .feature-content .content-accordian-img {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            gap: 20px;

            .acc-img {
                height: 570px;
            }

            .accordian-features-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 30px;
                width: 60%;

                .p-accordion .p-accordion-header .p-accordion-header-link {
                    padding: 0.5rem 0rem 0.5rem 0rem;
                    border-bottom: 4px solid #8daf61 !important;
                }

                .p-accordion .p-accordion-content {
                    padding: 0.5rem 0rem 0.5rem 0rem;
                    border-bottom: none !important;
                }

                p {
                    font-size: 20px;
                    line-height: 1.6;
                    color: #433f3e;
                }

                .feature-accordian-header {
                    h3 {
                        color: #000000 !important;
                        font-size: 22px !important;
                        font-weight: 700;
                        line-height: 1.4;
                    }

                    span i {
                        font-size: 22px;
                        font-weight: 700;
                        color: #8daf61;
                    }
                }

                .features-button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }
        }
    }
}

svg.p-icon.p-accordion-toggle-icon {
    display: none;
}

.p-accordion-header-text {
    width: 100% !important;
}

.accordion-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    border-bottom: 4px solid #352750 !important;
    background: none !important;
}

.p-accordion .p-accordion-content {
    border: none !important;
    border-bottom: 4px solid #352750 !important;
    background: none !important;
    color: #000000 !important;
    font-size: 20px !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
}

.p-accordion .p-accordion-tab {
    margin-bottom: 14px !important;
}

@media screen and (max-width: 1440px) {
    .s2l-academy-features {
        padding: 50px;

        .feature-container .feature-header .feature-content {
            .content-accordian-img {
                .acc-img {
                    height: 540px;
                }

                .accordian-features-content {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-features {
        padding: 50px;

        .feature-container .feature-content .content-accordian-img {
            .acc-img {
                height: 400px;
            }

            .accordian-features-content {
                width: 50%;

                .feature-accordian-header {
                    h3 {
                        margin: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .s2l-academy-features {
        padding: 30px;

        .feature-container {
            .feature-header {
                h2 {
                    font-size: 28px;
                    line-height: 1.2;
                }

                p {
                    font-size: 20px;
                }
            }

            .feature-content {
                .content-accordian-img {
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 15px;

                    .acc-img {
                        height: 480px;
                        width: auto;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .accordian-features-content {
                        width: 100%;

                        p {
                            font-size: 18px;
                        }
                    }
                }

                .accordian-features-content {
                    .key-feature {
                        display: none !important;
                    }

                    .feature-accordian-header {
                        h3 {
                            font-size: 20px;
                            margin: 5px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .s2l-academy-features {
        padding: 20px 20px 35px 20px;

        .feature-container {
            .feature-header {
                h2 {
                    font-size: 24px;
                    line-height: 1.2;
                }

                p {
                    font-size: 18px;
                }
            }

            .feature-content {
                .content-accordian-img {
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 15px;

                    .acc-img {
                        height: 280px;
                        width: auto;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .accordian-features-content {
                        width: 100%;

                        p {
                            font-size: 18px;
                        }
                    }
                }

                .accordian-features-content {
                    .key-feature {
                        display: none !important;
                    }

                    .feature-accordian-header {
                        span i {
                            font-size: 20px !important;
                        }
                    }

                    .feature-accordian-header {
                        h3 {
                            font-size: 20px;
                            margin: 4px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-features {
        padding: 15px 15px 35px 15px;

        .feature-container {
            .feature-header {
                h2 {
                    font-size: 24px;
                    line-height: 1.2;
                }

                p {
                    font-size: 18px;
                }
            }

            .feature-content {
                .content-accordian-img {
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 15px;

                    .acc-img {
                        height: 280px;
                        width: auto;

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .accordian-features-content {
                        width: 100%;

                        p {
                            font-size: 18px;
                        }
                    }
                }

                .accordian-features-content {
                    .key-feature {
                        display: none !important;
                    }

                    .feature-accordian-header {
                        span i {
                            font-size: 18px !important;
                        }
                    }

                    .feature-accordian-header {
                        h3 {
                            font-size: 20px;
                            margin: 4px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
}