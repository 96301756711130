.upcomingevents-container {
    margin: 9rem 3rem;
}

.ue-heading {
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ue-list {
    padding: 1rem 0rem;
}

.ue-event-card {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    margin: 2rem 0rem;
}

.ue-left-details {
    border: 1px solid rgba(255, 255, 255, 0.15);
    align-content: center;
}

.ue-closing {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ue-day {
    color: #FFF;
    font-family: Poppins;
    font-size: 90px;
    font-style: normal;
    font-weight: 250;
    line-height: normal;
}

.ue-month {
    color: #FFF;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0px 6px;
}

.closing-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    padding-bottom: 10px;
}

.ue-center-details {
    padding: 1rem 2rem !important;
}

.ue-name {
    color: #FFF;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ue-price-details {
    padding: 30px !important;
}

.ue-price {
    border-radius: 15px;
    background: rgba(34, 13, 80, 0.62);
    padding: 12px;
    text-align: center;
}

.ue-bottom-details {
    padding: 2rem;
}

.ue-desc {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    /* 22.68px */
}

.ue-link-button {
    text-align: right !important;
}

.event-link-button {
    background-color: transparent !important;
    border: 2px solid #FFF !important;
    color: #FFF !important;
    padding: 12px !important;
}