.speaker-details-container {
    padding: 8rem 4rem 2rem;
}

.speakerimage {
    width: 100%;
}

.speakername {
    color: #000;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.speakerdesignation {
    color: #808080;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.speakerdescription {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.speaker-details-container .back-link a {
    text-decoration: none !important;
    display: flex;
    place-items: center;
    color: #3183FF;
    font-size: 20px;
}

@media screen and (max-width:1024px) {
    .speaker-details-container {
        padding: 8rem 2rem 2rem;
    }

    .speakername {
        font-size: 24px !important;
    }

    .speakerdesignation {
        font-size: 18px;
    }

    .speakerdescription {
        font-size: 16px;
    }
}

@media screen and (max-width:991px) {
    .speaker-section-image {
        text-align: center;

        img {
            width: 60%;
        }
    }
}

@media screen and (max-width:490px) {
    .speaker-details-container {
        padding: 6rem 1.5rem 1rem;
    }

    .speakername {
        font-size: 22px !important;
    }

    .speakerdesignation {
        font-size: 16px;
    }

    .speakerdescription {
        font-size: 18px;
    }

    .speaker-section-image img {
        width: 80%;
    }
}