.footer {
    background-color: #220D50;
    color: white;
    padding: 2rem;
    padding-bottom: 1px;

    .logo {
        width: 300px;
        margin-bottom: 10px;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        padding-bottom: 2rem;
        width: 100%;

        .footer-left {
            display: flex;
            flex-direction: column;
            width: 60%;
            justify-content: center;

            .footer-nav {
                margin-bottom: 20px;

                ul {
                    display: flex;
                    gap: 20px;
                    list-style: none;
                    padding: 0;
                    flex-wrap: wrap;

                    li {
                        a {
                            color: white;
                            text-decoration: none;
                            font-size: 18px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .social-icons {
                display: flex;
                gap: 20px;
                font-size: 24px;
                margin-bottom: 20px;

                a {
                    color: white;
                    transition: color 0.3s;

                    &:hover {
                        color: #88b04b;
                    }
                }
            }
        }

        .footer-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;

            .newsletter {
                p {
                    font-size: 20px;
                    margin-bottom: 10px;
                    font-weight: 500;
                }

                form {
                    display: flex;
                    gap: 2rem;
                    width: 100%;
                }
            }

            .contact-info {
                margin-top: 20px;
                flex-direction: column;

                p:first-child {
                    font-size: 20px;
                    margin-bottom: 10px;
                    font-weight: 500;
                }

                p {
                    font-size: 16px;
                }
            }
        }

        border-bottom: 2px solid white;
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .legals {
            display: none;
        }

        .links-lists {
            display: flex;
            gap: 15px;
            align-items: center;
            flex-wrap: wrap;
            padding-right: 20px;


            a {
                color: #ffffff;
                text-decoration: none;
                font-size: 16px;
                display: flex;
                flex-wrap: wrap;

                &:hover {
                    text-decoration: underline;
                }
            }

            p,
            span {
                font-size: 16px;
                color: #ffffff;
            }
        }
    }

    .rights-section {
        text-align: center;
    }
}

@media screen and (max-width: 1200px) {
    .footer {
        .footer-content {
            .footer-left .footer-nav {
                margin-bottom: 20px;

                ul {
                    display: flex;
                    gap: 15px;

                    li a {
                        font-size: 16px;

                    }
                }
            }

            .footer-right {

                .newsletter {
                    p {
                        font-size: 18px;
                    }

                    form {
                        gap: 10px;
                    }
                }

                .contact-info {

                    p:first-child {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 920px) {
    .footer .footer-content .footer-right {
        .newsletter form {
            flex-direction: column;
            gap: 10px;

            .w-9 {
                width: 100% !important;
            }

            .p-button {
                display: block;
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 1.5rem;
        padding-bottom: 0;

        .logo {
            width: 280px;
        }

        .footer-content {
            flex-direction: column;
            padding-bottom: 0;

            .footer-left {
                flex: none;
                margin-bottom: 0;
                width: 100%;

                .footer-nav {
                    order: 1;
                    border-bottom: none;
                    margin-bottom: 0;

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        li {
                            margin-right: 10px;
                            font-size: 30px;
                            display: contents;

                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .social-icons {
                    border-top: 2px solid white;
                    order: 0;
                    margin-top: 0.5rem;
                    padding-top: 1rem;
                }

                .footer-nav li:not(:last-child)::after {
                    content: "|";
                    margin-left: 10px;
                }

                .footer-bottom {
                    order: 2;
                    padding: 0;

                    .legals {
                        display: block !important;
                        text-align: left;
                    }

                    .links-lists {
                        padding-right: 0;
                        flex-direction: column;
                        gap: 0px;

                        a {
                            justify-content: center;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .footer-right {
                flex: none;
                margin-bottom: 20px;
                margin-left: 0;
                width: 100%;

                .newsletter p {
                    border-bottom: 2px solid white;
                }
            }

            .social-icons {
                justify-content: center;
            }
        }

        .footer-bottom {
            flex-direction: column;

            a {
                margin: 5px 0;
            }
        }
    }
}

@media screen and (max-width: 490px) {
    .footer {
        .logo {
            width: 220px;
        }

        .footer-content {
            .footer-left {

                .footer-nav ul li a {
                    font-size: 14px;
                }

                .social-icons {
                    font-size: 20px;
                }
            }

            .footer-right .contact-info p {
                font-size: 14px;
            }
        }

        .footer-bottom {
            padding: 1rem;

            a,
            p {
                font-size: 14px;
            }
        }
    }
}