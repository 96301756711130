.appointment-container {
    padding: 2rem 4rem;
    margin-bottom: 2rem;

    .appintment-sec {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 50px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        object-fit: cover;
        padding: 0 4rem;

        .ac-part-one {
            width: 65%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            gap: 1rem;

            .ac-title {
                font-size: 38px;
                font-weight: 700;
                text-align: left;
                margin: 0;
            }

            .ac-content {
                font-size: 27px;
                font-weight: 600;
                margin: 0;
                width: 95%;
            }
        }

        .ac-part-two {
            width: 35%;
            padding: 4rem 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .ac-talk-now {
                .calendly-button {
                    font-size: 20px;
                    font-weight: 700;
                    border-radius: 20px;
                    padding: 2rem 2rem;
                    text-align: center;
                    width: 100%;
                    background-color: white;
                    cursor: pointer;
                    border: 2px solid white;
                    color: #f75b75 !important;
                    transition: all 0.3s;

                    &:hover {
                        background-color: #f75b75 !important;
                        color: white !important;
                        border: 2px solid white;
                    }
        
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .appointment-container {


        .appintment-sec {
            padding: 2rem 4rem;
            .ac-part-one {
                .ac-title {
                    font-size: 40px;
                }

                .ac-content {
                    font-size: 21px;
                }
            }

            .ac-part-two {
                width: 40%;
                padding: 2rem 0;

                .ac-talk-now {
                    font-size: 22px;
                    padding: 1rem;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .appointment-container {
        .appintment-sec {
            flex-direction: column;

            .ac-part-one {
                width: 100%;
                padding: 1rem 0rem;

                .ac-title {
                    font-size: 40px;
                    display: flex;
                    justify-content: center;
                }

                .ac-content {
                    display: flex;
                    justify-content: center;
                    font-size: 20px;
                }
            }

            .ac-part-two {
                width: 100%;
                padding-top: 1rem;
                padding-bottom: 0rem;

                .ac-talk-now {
                    font-size: 24px;
                    padding: 2rem 1rem;
                    width: 80%;
                }
            }
        }
    }
}

// @media (max-width:1008px) {
//     .appointment-container {
//         padding: 2rem 3rem;
//     }
// }

@media (max-width: 768px) {
    .appointment-container {
        padding: 2rem 2rem;
        margin-bottom: 1rem !important;

        .appintment-sec {
            padding: 2rem 3rem !important;
            gap: 1rem;
            .ac-part-one {
                padding: 1rem 0rem;

                .ac-title {
                    font-size: 30px;
                }

                .ac-content {
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                }
            }

            .ac-part-two {
                width: 100%;
                padding-top: 0rem;

                .ac-talk-now {
                    font-size: 20px;
                    padding: 1.5rem 1.5rem;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 490px) {
    .appointment-container {
        padding: 1rem 1rem;

        .appintment-sec {
            padding: 1rem 2rem !important;
            gap: .5rem;
            .ac-part-one {
                padding: 1rem 0rem;

                .ac-title {
                    font-size: 23px;
                    text-align: center;
                }

                .ac-content {
                    font-size: 17px;
                }
            }

            .ac-part-two {
                padding-bottom: .5rem;

                .ac-talk-now {
                    font-size: 16px;
                   width: 100% !important;
                   padding: 1rem;
                }
            }
        }
    }
}