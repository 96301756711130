.auth-section {
    height: auto;
    width: 100%;
}

.logos {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 15%;

    img {
        margin: 20px;
        width: 250px;
    }
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;

    .login-card {
        width: 70%;
        box-shadow: 1px -1px 2px 1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

        .login-section {
            display: flex;
            align-items: center;
            gap: 20px;

            .login-detail-section {
                padding: 2rem;
                display: grid;
                align-items: center;
            }

            h2 {
                margin: 0;
                font-size: 40px;
            }

            p {
                font-size: 18px;
                color: #9F9C9C;
            }

        }

        .login-img {
            img {
                height: 500px;
            }
        }

        .p-password-input {
            width: 100%;
        }

        .auth-footer-section {
            span {
                color: #419bee;
                cursor: pointer;
            }
        }

        .login-footer {
            display: flex;
            justify-content: center;
            margin-top: 1rem;

            .signup-button {
                color: #419bee;
                cursor: pointer;
            }
        }
    }
}

.error-message {
    font-weight: 400;
    font-size: 16px !important;
    color: #E04A4A !important;
}

.error .p-inputtext {
    border: 1px solid #E04A4A !important;
}

.email-error {
    border: 1px solid #E04A4A !important;
}

@media screen and (max-width: 1440px) {
    .login-page {

        .login-card {

            .login-section {

                .login-img {
                    img {
                        height: 400px;
                    }
                }

                h2 {
                    font-size: 36px;
                }
            }
        }
    }
}

@media screen and (max-width: 1220px) {
    .login-page {
        .login-card {
            width: 90%;

            .login-section {
                h2 {
                    font-size: 34px;
                }
            }
        }
    }
}

@media screen and (max-width:964px) {
    .logos img {
        width: 220px;
    }

    .login-page {
        .login-section {
            h2 {
                font-size: 26px !important;
            }

            .login-detail-section {
                padding: 1rem !important;
            }
        }

        .login-img {
            display: none;
        }
    }
}