.dashboard-container {
  display: flex;
  width: 100%;

  .left-bar {
    width: 275px;
    height: 100vh;
    border-right: 1px solid #f8f9fa;
    transition: transform 0.3s ease-in-out;

    &.show {
      transform: translateX(0); /* Sidebar visible */
    }

    .logo {
      text-align: center;
      padding: 2rem 0.5rem;

      img {
        width: 255px;
        height: 100%;
      }
    }

    .avatar-section {
      display: grid;
      justify-content: center;
      align-items: center;

      .student-name {
        font-size: 26px;
        text-align: center;
        padding: 0.5rem 0;
      }
      .student-grade {
        text-align: center;
      }
    }

    .menu-items ul {
      padding-left: 0;
      cursor: pointer;

      li {
        list-style: none;
        padding: 20px;
        border-bottom: 1px solid #bfbfbf;
        display: flex;
        align-items: center;
        &.active {
          background-color: #f9ab60;
          font-weight: bold;
        }
      }
    }
  }

    .menu-item-icon {
        margin-right: 8px;
    }


  @media (max-width: 1008px) {
    .left-bar {
      position: absolute;
      transform: translateX(-100%);
      z-index: 100;
      width: 275px;
      background-color: #f8f9fa;
    }
    .profile-item {
      display: none;
    }
  }

  .header {
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: hidden;

    .stack-navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
      .stackbar-heading {
        img {
          display: none;
        }
      }

      .menu-toggle {
        display: none;
        cursor: pointer;

        .bar {
          width: 25px;
          height: 3px;
          background-color: #333;
          margin: 5px 0;
          transition: 0.4s;
        }
      }

      @media (max-width: 1008px) {
        .menu-toggle {
          display: block;
        }
      }

      .profileIcon {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        svg:first-child {
          width: 40px;
          height: 40px;
        }
      }
    }
    .show-active-section {
      display: none;
    }

    .menu-content-section {
      flex-grow: 1;
      overflow-y: auto;
      scrollbar-width: none;
    }
  }

  @media (max-width: 1008px) {
    .header {
      width: 100%;
      padding-left: 0;
    }

    .menu-content-section {
      padding: 0rem;
    }
    .menu-container {
      margin-top: 20px;
    }
  }
}

.profile-menu-list {
  list-style: none !important;
  margin: 0px 20px 0px 0px;
}
.menu-item-icon {
  margin-right: 8px;
}



@media (max-width: 490px) {
  .dashboard-container {
    .left-bar {
      width: 80vw;
      .logo {
        img {
          display: block;
          // width: 191.2px;
          // height: 36px;
          // gap: 0px;
          // opacity: 0px;
        }
      }
    }
    .header {
      .stack-navbar {
        justify-content: space-between;
        padding: 1rem 1rem;
        .stackbar-heading {
          width: 100%;
          h2 {
            display: none;
            text-align: center;
            font-size: 16px;
          }
          img {
            display: block;
            width: 255px;
            height: 100%;
          }
        }
      }
      .show-active-section {
        display: block;
        text-align: center;
        padding: 1rem;
        background: #ececec;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 20px 10px 20px;
        opacity: 0px;
      }
    }
  }
}
