.info-section {
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  width: 100%;

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 10.5px !important;
    background: none !important;
    border-width: 0 !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: none !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      color: #FFFFFF !important;
  }

  .p-tabmenu-nav .p-menuitem-text {
      color: #FFFFFF;
  }

  .p-tabmenu .p-tabmenu-nav {
      // margin: 60px 0 0 0;
      line-height: 34px;
      position: relative;
      text-align: center;
      display: block;
      border: none !important;

      .p-tabmenuitem {
          position: relative;
          background-color: #8daf61;
          padding: 10px 15px;
          border-radius: 0 !important;
          margin: 10px 30px;
          display: inline-block;

          &:before,
          &:after {
              content: '';
              position: absolute;
              height: 0;
              width: 0;
              border-style: solid;
          }

          &:before {
              border-width: 28px 0 27px 35px;
              border-color: #8daf61 transparent;
              left: -34px;
              top: 0;
          }

          &:after {
              border-width: 27px 0 28px 37px;
              border-color: transparent #8daf61;
              right: -36px;
              top: 0px;
          }

          &.p-highlight {
              background-color: #220D50;

              &:before {
                  border-color: #220D50 transparent;
              }

              &:after {
                  border-color: transparent #220D50;
              }
          }
      }
  }
}

.additionalInfo-Section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .info-form-sec {
      padding: 2rem;
      width: 100%;
  }

  .parentinfoImg {
      display: flex;
      align-items: center;

      img {
          width: 500px;
      }
  }
}

.info-section-accordian {
  display: none !important;
}

@media (max-width: 1008px) {
  .info-section {
    display: none;
  }
  .info-section-accordian {
    display: flex !important;
    flex-direction: column;
    padding-top: 120px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;

    .info-accordian-content {
      .p-accordion-header {
        background-color: #220d50;
      }
      .p-accordion .p-accordion-header .p-accordion-header-link {
        color: #ffffff;
      }

      .p-accordion-tab {
        margin-bottom: 10px !important;
      }

      .p-accordion .p-accordion-header .p-accordion-header-link {
        border-bottom: transparent !important;
      }
      .p-accordion
        .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
        .p-accordion-header-link {
        color: #ffffff !important;
      }
      .p-accordion
        .p-accordion-header:not(.p-disabled).p-highlight:hover
        .p-accordion-header-link {
        color: #ffffff !important;
      }

      .p-accordion .p-accordion-content {
        border-bottom: transparent !important;
      }

      .additionalInfo-Section {
        .p-fluid {
          padding: 0rem !important;
        }

        h2 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
          line-height: 22px;
        }

        .info-form-sec {
          padding: 0rem;

          .block {
            font-size: 16px;
          }
        }

        .addtionalInfo-aoi-content {
          padding: 0px !important;

          .aoi-desc {
            font-size: 16px !important;
          }
        }
      }
    }

    .parentinfoImg {
      display: none;
    }
  }
}

@media (max-width: 390px) {
  .info-section-accordian {
    padding-left: 7px;
    padding-right: 7px;

    .p-accordion .p-accordion-content {
      padding: 0.5rem !important;
    }
  }
}


.info-section-accordian {
  display: none !important;
}

@media (max-width: 1008px) {
  .info-section {
    display: none;
  }
  .info-section-accordian {
    display: flex !important;
    flex-direction: column;
    padding-top: 120px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;

    .info-accordian-content {
      .p-accordion-header {
        background-color: #220d50;
      }
      .p-accordion .p-accordion-header .p-accordion-header-link {
        color: #ffffff;
      }

      .p-accordion-tab {
        margin-bottom: 10px !important;
      }

      .p-accordion .p-accordion-header .p-accordion-header-link {
        border-bottom: transparent !important;
      }
      .p-accordion
        .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
        .p-accordion-header-link {
        color: #ffffff !important;
      }
      .p-accordion
        .p-accordion-header:not(.p-disabled).p-highlight:hover
        .p-accordion-header-link {
        color: #ffffff !important;
      }

      .p-accordion .p-accordion-content {
        border-bottom: transparent !important;
      }

      .additionalInfo-Section {
        .p-fluid {
          padding: 0rem !important;
        }

        h2 {
          font-size: 22px;
        }

        p {
          font-size: 18px;
          line-height: 22px;
        }

        .info-form-sec {
          padding: 0rem;

          .block {
            font-size: 16px;
          }
        }

        .addtionalInfo-aoi-content {
          padding: 0px !important;

          .aoi-desc {
            font-size: 16px !important;
          }
        }
      }
    }

    .parentinfoImg {
      display: none;
    }
  }
}

@media (max-width: 390px) {
  .info-section-accordian {
    padding-left: 7px;
    padding-right: 7px;

    .p-accordion .p-accordion-content {
      padding: 0.5rem !important;
    }
  }
}
