.topic-details-container {
    background: #F5F1FD;
    padding: 2rem;
    max-height: 87vh; // Adjust this value as needed
    overflow-y: auto; // Enable vertical scrolling

    .tabs {
        .p-tabview .p-tabview-nav {
            display: flex !important;
            justify-content: center !important;
        }
    }
}

.topic-header {
    text-align: center;
}

.topic-name {
    padding: 1rem;
    color: #000;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 87%;
    /* 27.84px */
    letter-spacing: -0.96px;
}



.tabview-custom {
    margin: 2rem;
}

.n-subtext {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    /* 29px */
}

.overview {
    color: #000;

    img {
        width: -webkit-fill-available;
    }
}

.sub-title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 24px */
}

.desc {
    padding: 10px 0px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    /* 29px */
}

.skills {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    /* 29px */
}

.keypoints-heading {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* 29px */
    text-decoration-line: underline;
}

.keypoints {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    /* 29px */
}

.note-form {
    margin-bottom: 2rem;
}

.note-card {
    margin-bottom: 1rem;
}

.each-note {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.each-note:hover .view-edit-icons {
    opacity: 1;
}

.note-header {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: var(--Secondary-Secondary, #220D50);
    padding: 15px;
}

.header-left {
    color: #FFF;
    display: flex;
    align-items: center;
}

.note-type {
    padding-left: 4px;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.note-date {
    color: #000;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    text-align: right;
}

.note-icon {
    width: 20px;
    height: 20px;
}

.note-content {
    padding: 10px;
}

.note-desc {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.upload-notes-btn {
    margin-top: 3rem;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 6px;
    border-top-left-radius: 6px;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    font-size: 16px;
}

.view-edit-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin: 20px 10px;
    color: #000;
}

.view-edit {
    margin-right: 10px;
    color: #FFFF;
    padding: 4px 10px;
    cursor: pointer;
    border-radius: 6px;
}

.edit-note {
    padding-top: 20px;
}

.view-edit-icons {
    opacity: 0; // Initially hidden
    transition: opacity 0.3s ease;
    width: 20px;
    height: 20px;
    float: right;

}

.mynotes.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 2rem 1.5rem 2rem 1.5rem !important;
}

.view-icons {
    width: 20px;
    height: 20px;
    float: right;
}


.my-teachers-topic {
    .my-teacher-header {}

    .my-teachers-content {
        .teacher {
            border: 1px solid #220D50;
            border-radius: 10px;
            overflow: hidden;

            .teacher-header {
                background-color: #220D50;

                color: #ffffff;

                p {
                    margin: 0px !important;
                    padding: 20px 30px;
                }
            }
            .teacher-content {
                padding: 5px 50px;
            }
            .teacher-contact-us {
                padding: 0px 50px 20px;
                .teacher-contact {
                    background: #220D50 !important;

                }
            }
        }
    }

}




.p-editor-toolbar {
    display: none;
}