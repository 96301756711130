.workshops-container {
    padding: 7rem 3rem;
}

.workshop {
    border-bottom: 2px solid #00000026;
}

.w-name {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    font-family: poppins;
    padding: 8px 0px;
}

.w-date-time {
    font-style: italic;
    font-size: 18px;
    font-weight: 500;
    color: #737373;
    font-family: poppins;
    font-style: italic;
}

.w-tag {
    font-family: poppins;
    padding: 0px 8px;

    .p-tag {
        color: #FFF !important;
        font-size: 16px;
        font-weight: 500;
    }

}

.w-description {
    margin: 4px;
    font-size: 18px;
    font-weight: 400;
    color: #808080;
    font-family: poppins;
}

.rm-link {
    font-size: 18px;
    font-weight: 500;
    color: #3183FF;
    font-family: poppins;
    padding: 0px 0px 15px 0px;
    display: flex;
    justify-content: flex-end;

    a {
        text-decoration: none !important;
        color: #3183FF;
    }
}

.workshops-right-section {
    padding: 8px 2rem 8px 3rem !important;
}

.search-title {
    font-size: 18px;
}

.no-results {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    font-family: poppins;
    padding: 8px 0px;
}

@media screen and (max-width:767px) {
    .workshops-right-section {
        padding: 0rem !important;
    }

    .workshops-grid {
        flex-direction: column-reverse;
    }
}