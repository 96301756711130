.dashboard-title {
  margin-top: 2rem;
  text-align: center;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 87%;
  /* 27.84px */
  letter-spacing: -0.96px;
}

.courses-container {
  margin: 2rem;
  // max-height: 87vh; // Adjust this value as needed
  // overflow-y: auto; // Enable vertical scrolling
  // overflow-x: hidden; // Disable horizontal scrolling

  // margin-top: 5rem;
  .p-card .p-card-body {
    padding: 0rem !important;
  }

  .p-card .p-card-content {
    padding: 0 !important;
  }

  .p-card {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.three-dots-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
}

.card-details {
  color: #fff;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // border-radius: 0px 0px var(--Spacing-sm, 10px) var(--Spacing-sm, 10px);
  background: var(--Secondary-Secondary, #220d50);
  text-align: left;
}

.course-title {
  color: #fff;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 87%;
  /* 17.4px */
  letter-spacing: -0.6px;
}

.enrolled-label {
  padding: 6px;
  width: fit-content;
  font-weight: 700;
  border-radius: 4px;

  &:hover {
    background-color: white;
    color: #220d50;
  }
}

.course-date {
  color: #fff;
  padding: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  // line-height: 87%;
  /* 17.4px */
  letter-spacing: -0.6px;
}

.to-do {
  border-left: 1px solid #000;
}

.completion-track-container {
  text-align: center;
  margin: 20px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

.step-container {
  display: flex;
  align-items: center;
  position: relative;
}

.dot {
  margin-top: 5px;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 4px solid black;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.last-dot {
  width: 100px;
  height: 70px;
  border: none;
  background-color: transparent;
}

.c-image {
  width: 100px;
  height: 70px;
  object-fit: cover;
}

.progress-bar::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 6px;
  background-color: #401998;
  z-index: 1;
}

.label-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 1000px;
  margin: 10px auto;
}

.label-container span {
  font-size: 14px;
  color: black;
}

/* Container for the list of topics */
.topics-list {
  background: rgba(229, 219, 249, 0.4);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.my-course-heading {
  font-size: 24px;
}

/* Card styling */
.course-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  width: 100%;
}

/* Hover effect for the card */
.course-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Button styling */
.go-to-topic-btn {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s;
  background: var(--Secondary-Secondary, #220d50);
}

.topic-title {
  padding-bottom: 6px;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 28.8px */
}

.time {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  padding-bottom: 10px;
  /* 17.4px */
}

.t-desc {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  padding-bottom: 10px;
  /* 29px */
}

@media screen and (min-width: 320px) and (max-width: 390px) {
  .courses-container {
    .courses-section-grid {
      .p-paginator .p-paginator-first,
      .p-paginator .p-paginator-prev,
      .p-paginator .p-paginator-next,
      .p-paginator .p-paginator-last {
        height: 1.5rem;
        min-width: 1.5rem;
      }

      .p-paginator .p-paginator-pages .p-paginator-page {
        height: 1.5rem;
        min-width: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 391px) and (max-width: 490px) {
  .courses-container {
    .courses-section-grid {
      .p-paginator .p-paginator-first,
      .p-paginator .p-paginator-prev,
      .p-paginator .p-paginator-next,
      .p-paginator .p-paginator-last {
        height: 2rem;
        min-width: 2rem;
      }

      .p-paginator .p-paginator-pages .p-paginator-page {
        height: 2rem;
        min-width: 2rem;
      }
    }
  }
}
