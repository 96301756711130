.popup-container {
    position: relative;
    width: 300px;

    .chat-popup {
        position: absolute;
        bottom: 150px;
        right: 20px;
        z-index: 999;
        background: linear-gradient(135deg, #8DAF61 0%, rgb(209 241 251) 100%);
        color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        max-width: 400px;
        height: 538px;
        width: 370px;
        box-shadow: 0 2px 5px rgb(57 97 45 / 91%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;

        &.open {
            opacity: 1;
            visibility: visible;
            position: fixed;
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            border: none;
            background: none;
            font-size: 35px;
            cursor: pointer;
            color: #FFFFFF;
        }

        .chat-history {
            max-height: 280px;
            height: 280px;
            margin-bottom: 10px;
            font-size: 16px;
            display: flex;
            // flex: 1;
            flex-direction: column !important;
            overflow-y: auto;

            .message {
                padding: 8px;
                margin: 8px;
                border-radius: 8px;
                max-width: 70%;
            }

            .user {
                background-color: #ffffff;
                align-self: flex-end;
                font-size: 14px;
                color: #352750;
                word-wrap: break-word;
            }

            .assistant {
                background-color: #352750;
                align-self: flex-start;
                font-size: 14px;
            }

            .chat-message {
                margin-bottom: 10px;

                &.user {
                    text-align: right;
                }

                span {
                    font-weight: bold;
                }

                p {
                    margin: 5px 0;
                }
            }
        }

        .chat-history::-webkit-scrollbar {
            display: none;
        }

        .prompts {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 10px 0;
            height: 120px;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .prompt-button {
            background: #FFFFFF;
            color: black !important;
            border: none;
            border-radius: 15px;
            padding: 5px 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            font-size: 12px;
        }

        .prompt-button:hover {
            background: #EFE5D9;
        }
    }

    .chat-icon {
        position: fixed;
        bottom: 80px;
        right: 20px;
        // background-color: #352750;
        background-color: #6fac4d;
        color: white;
        border: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
    }

    .input-container {
        .p-inputtext {
            width: 100% !important;
        }

        .p-button {
            font-size: 18px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
        }
    }
}

@media (max-width: 500px) {
    .popup-container {
        .chat-popup {
            max-width: 300px;
            width: 285px;
        }

        .p-inputtext {
            width: 100%;
        }

        .p-button {
            width: 50%;
        }
    }

    .popup-container .chat-popup {
        height: 450px;
        width: 370px;
    }

    .popup-container .chat-popup .chat-history {
        height: 220px;
    }

    .popup-container .chat-popup .prompts {
        height: 100px;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
    }
}