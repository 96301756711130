.s2l-academy-events {
    padding: 60px;
    background-color: #feeedf;

    .events-container {
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
            text-align: center;
        }

        .event-content {
            margin-top: 35px;
            justify-content: space-around;
            gap: 10px;

            .event {
                background-color: #220d50;
                color: #ffff;
                padding: 20px 20px 30px 20px;

                .event-image {
                    height: 371px;
                    width: auto;

                    img {
                        object-fit: cover;
                        width: 100%;
                    }
                }

                .event-details {
                    h3 {
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 30px;
                        text-align: center;
                    }

                    .event-details-sub {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        gap: 25px;

                        .event-data {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;

                            i {
                                font-size: 1.5rem;
                            }

                            p {
                                line-height: 1.6;
                            }
                        }
                    }

                    // .event-btn {
                    //     display: flex;
                    //     flex-direction: row;
                    //     justify-content: center;
                    // }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .s2l-academy-events {
        padding: 50px;

        .events-container .event-content {
            gap: 30px;

            .event {
                padding: 15px 15px 30px 15px;

                .event-image {
                    height: 340px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .s2l-academy-events {
        padding: 50px;

        .events-container {
            .event-content {
                gap: 30px;

                .event {
                    padding: 15px 15px 30px 15px;


                    .event-image {
                        height: 290px !important;
                    }

                    .event-details h3 {
                        font-size: 22px;
                    }

                    .event-details-sub {
                        display: flex;
                        flex-direction: column !important;
                        justify-content: center !important;
                        align-items: center !important;
                        gap: 0px !important;
                        margin-bottom: 10px;

                        .event-data {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .s2l-academy-events {

        .events-container {
            h2 {
                font-size: 28px;
                line-height: 1.2;
            }

            .event-content {
                margin-top: 20px;
                flex-direction: row !important;
                gap: 15px;

                .event {
                    padding: 20px 20px 30px 20px;

                    .event-image {
                        height: 340px !important;
                    }

                    .event-details h3 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-events {
        padding: 15px 30px 30px 30px;

        .events-container .event-content {
            flex-direction: column !important;

           

        }

    }
}

@media screen and (max-width: 575px) {
    .s2l-academy-events {
        padding: 20px;

        .events-container {
            h2 {
                font-size: 24px;
                line-height: 1.2;
            }

            .event-content {
                margin-top: 20px;
                flex-direction: column !important;
                gap: 15px;

                .event {
                    padding: 20px 20px 30px 20px;

                    .event-image {
                        height: 280px !important;
                    }

                    .event-details {
                        h3 {
                            font-size: 22px;
                        }

                        .event-details-sub {
                            display: flex;
                            flex-direction: column !important;
                            justify-content: center !important;
                            align-items: center !important;
                            gap: 0px !important;

                            .event-data {
                                justify-content: flex-start !important;
                                width: 80%;

                                i {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-events {
        padding: 15px 15px 35px 15px;

        .events-container {
            h2 {
                font-size: 24px;
                line-height: 1.2;
            }

            .event-content {
                margin-top: 20px;
                flex-direction: column !important;
                gap: 15px;

                .event {
                    padding: 15px 15px 30px 15px;

                    .event-image {
                        height: 177px !important;
                    }
                }
            }
        }
    }
}