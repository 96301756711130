.s2l-academy-ourstory {
    padding: 20px 60px;

    .ourstory-container {
        h2 {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
        }

        .story-content p {
            font-size: 20px;
            line-height: 1.6;
            text-align: center;
            font-weight: 400;
        }

        .our-services-abtus {
            p {
                font-size: 20px;
                line-height: 1.6;
                text-align: center;
            }

            .about-services-list {
                .about-service-item {
                    width: 24%;
                    margin: 0% 1% 2% 0%;
                    box-shadow: 4px 4px 4px 0px #00000040;

                    p {
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-ourstory {
        padding: 20px 40px !important;
    }
}

@media screen and (max-width: 920px) {
    .s2l-academy-ourstory {
        .ourstory-container {
            h2 {
                font-size: 32px;
                line-height: 1.2;
            }

            .story-content p {
                font-size: 20px;
                line-height: 1.6;
            }

            .our-services-abtus {
                p {
                    font-size: 20px;
                    line-height: 1.6;
                }

                .about-services-list .about-service-item {
                    width: 32% !important;
                    margin: 0% 1% 2% 0%;

                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-ourstory {

        .ourstory-container {
            .story-content p {
                line-height: 1.6;
                text-align: left !important;
            }

            .our-services-abtus {
                p {
                    line-height: 1.6;
                    text-align: left;
                }

                .about-services-list .about-service-item {
                    width: 49% !important;
                    margin: 0% 1% 2% 0%;

                    p {
                        font-size: 18px;
                    }
                }
            }
        }


    }
}

@media screen and (max-width: 520px) {
    .s2l-academy-ourstory {
        padding: 20px !important;

        .ourstory-container {
            .story-content p {
                font-size: 18px;
                line-height: 1.6;
                text-align: left !important;
            }

            .our-services-abtus {
                p {
                    font-size: 18px;
                    line-height: 1.6;
                    text-align: left;
                }

                .about-services-list .about-service-item {
                    width: 99% !important;
                    margin: 0% 1% 5% 0% !important;

                    p {
                        font-size: 18px;
                        text-align: center !important;
                    }
                }
            }
        }
    }
}