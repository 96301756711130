.s2l-academy-header {
    .academy-container {
        padding-top: 130px;
        .header-content {
            padding-left: 60px;
            padding-bottom: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h2 {
                font-size: 60px;
                line-height: 1.4;
                font-weight: 700;
            }

            p {
                color: #433f3e;
                font-size: 22px;
                line-height: 1.6;
                font-weight: 500;
            }
        }

        .header-bg {
            height: auto;
            width: 1060px;

            img {
                object-fit: cover;
            }
        }
    }

    .lg-sc-btn {
        .learn-more-btn-sm {
            display: none;
        }
    }
}

@media screen and (max-width: 1440px) {
    .s2l-academy-header {
        .academy-container {
            .header-content {
                padding-left: 50px;
                display: flex;
                padding-bottom: 50px;

                h2 {
                    font-size: 50px;
                    line-height: 1.5;
                }
            }

            .header-bg {
                height: auto;
                width: 950px;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .s2l-academy-header {
        .academy-container {
            .header-content {
                padding-left: 50px;
                display: flex;
                gap: 10px;

                h2 {
                    font-size: 40px;
                    line-height: 1.2;
                    margin: 0px;
                }

                p {
                    font-size: 20px;
                    margin: 0px;
                }
            }

            .header-bg {
                height: auto;
                width: 850px;

                img {
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (max-width: 920px) {
    .s2l-academy-header {
        padding: 0px 30px 30px 30px;

        .academy-container {
            flex-direction: column !important;
            gap: 30px !important;

            .header-content {
                padding-left: 0px;
                padding-bottom: 10px;

                h2 {
                    font-size: 36px;
                    line-height: 1.3;
                }

                p {
                    font-size: 20px;
                }

                .learn-more-btn-lg {
                    display: none !important;
                }
            }

            .header-bg {
                height: 550px;
                width: auto;

                .s2l-bg {
                    object-fit: cover;
                }
            }
        }

        .lg-sc-btn {
            .learn-more-btn-sm {
                display: block !important;
                margin-top: 15px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .s2l-academy-header {
        padding: 20px;

        .academy-container {
            flex-direction: column !important;
            gap: 10px !important;
            padding-top: 90px !important;

            .header-content {
                padding-left: 0px;

                h2 {
                    font-size: 32px;
                    line-height: 1.2;
                }

                p {
                    color: #433f3e;
                    font-size: 18px;
                }

                .learn-more-btn-lg {
                    display: none !important;
                }
            }

            .header-bg {
                height: auto;
                width: auto;

                .s2l-bg {
                    object-fit: cover;
                }
            }
        }

        .lg-sc-btn {
            .learn-more-btn-sm {
                display: block;
                margin-top: 15px;
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-header {
        margin-top: 0rem;

        .academy-container {
            flex-direction: column !important;
            gap: 10px !important;

            .header-content {
                padding-left: 0px;

                h2 {
                    font-size: 32px;
                    line-height: 1.2;
                }

                p {
                    color: #433f3e;
                    font-size: 18px;
                }

                .learn-more-btn-lg {
                    display: none !important;
                }
            }

            .header-bg {
                height: auto;
                width: auto;

                .s2l-bg {
                    object-fit: cover;
                }
            }
        }

        .lg-sc-btn {
            .learn-more-btn-sm {
                display: block;
                margin-top: 15px;
            }
        }
    }
}