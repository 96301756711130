.s2l-academy-pastevents {
    padding: 60px;

    .pastevents-container {
        h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
            text-align: center;
        }

        .pastevents-data {
            display: flex;
            flex-direction: row;
            gap: 15px;

            .pastevents-card {
                .p-card-content {
                    padding: 0px !important;
                }

                .p-card-body {
                    min-height: 640px;
                }

                .pastevents-card-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .pastevents-card-img {
                        img {
                            width: 100%;
                            height: 315px;
                            object-fit: cover;
                        }
                    }

                    .pastevents-header {
                        align-items: center;

                        img {
                            height: 50px;
                        }
                    }

                    .pastevents-details {

                        .date-location .pe-date,
                        .pe-location {
                            color: #9f9c9c;
                            font-size: 16px;
                        }

                        .pe-desc {
                            color: #433f3e;
                            font-size: 20px;
                            line-height: 1.6;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .pastevents-readmore {
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                        align-items: center;

                        .readmore-btn {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            color: #8daf61 !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .s2l-academy-pastevents {
        padding: 50px;

        .pastevents-container .pastevents-data .pastevents-card {
            .p-card-body {
                min-height: 605px !important;
            }

            .pastevents-card-content {
                .pastevents-card-img img {
                    width: 100%;
                    height: 250px !important;
                }

                .pastevents-header{
                    min-height: 95px !important;
                }
            }
        }

    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-pastevents {
        padding: 50px;

        .pastevents-container .pastevents-data .pastevents-card {
            .p-card-body {
                min-height: 550px !important;
            }
            .pastevents-card-content {
                .pastevents-card-img img {
                    width: 100%;
                    height: 220px !important;
                }

                .pastevents-header{
                    min-height: 125px !important;
                }

                .pastevents-details .date-location{
                    flex-direction: column !important;
                    p{
                        margin: 0px !important;
                    }

                }

                .pe-desc {
                    -webkit-line-clamp: 3 !important;
                }
            }

        }


    }
}

@media screen and (max-width: 1024px) {
    .s2l-academy-pastevents {
        padding: 50px;

        .pastevents-container .pastevents-data {
            flex-direction: column !important;

            .pastevents-card {

                .p-card-body {
                    min-height: 663px !important;
                }

                .pastevents-card-content {
                    .pastevents-card-img img {
                        width: 100%;
                        height: 380px !important;
                    }

                    .pastevents-header{
                        min-height: auto !important; 

                    }

                    .pastevents-details .date-location{
                        margin-top: 10px !important;
                        
    
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-pastevents {
        padding: 30px;

        .pastevents-container {
            h2 {
                font-size: 28px;
                line-height: 1.2;
            }

            .pastevents-data {

                .pastevents-card {
                    .p-card-body {
                        min-height: 570px !important;
                    }

                    .pastevents-card-content {
                        .pastevents-card-img img {
                            width: 100%;
                            height: 320px !important;
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .s2l-academy-pastevents {
        padding: 15px;

        .pastevents-container {
            h2 {
                font-size: 26px;
                line-height: 1.2;
            }

            .pastevents-data .pastevents-card .pastevents-card-content {
                .pastevents-card-img img {
                    width: 100%;
                    height: 280px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-pastevents {
        padding: 15px;

        .pastevents-container {
            h2 {
                font-size: 26px;
                line-height: 1.2;
            }

            .pastevents-data {
                .pastevents-card .pastevents-card-content {
                    .pastevents-card-img img {
                        width: 100%;
                        height: 250px !important;
                    }

                    .pastevents-details {
                        .date-location {
                            flex-direction: column !important;

                            .pe-date,
                            .pe-location {
                                font-size: 14px !important;
                                margin-bottom: 0px !important;
                            }

                        }

                        .pe-desc {
                            font-size: 18px !important;
                            -webkit-line-clamp: 3 !important;
                        }
                    }
                }
            }
        }
    }
}