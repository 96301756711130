.programs-section {
    text-align: center;
    padding: 2rem;

    h2 {
        font-size: 32px;
        margin-bottom: 2rem;
        color: #151413;
    }

    .programs-content {
        display: flex;
        justify-content: space-around;
        gap: 2rem;
        flex-wrap: wrap;

        .program-item {
            border-radius: 10px;
            width: 45%;
            text-align: left;

            img {
                width: 100%;
                height: auto;
                border-radius: 10px;
            }

            h3 {
                font-size: 24px;
                color: #151413;
                margin-top: 1rem;

                span {
                    color: #8DAF61;
                }
            }

            p {
                font-size: 20px;
                color: #433F3E;
                margin: 1rem 0;
            }

            .learn-more {
                font-size: 18px;
                color: #0B4982;
                text-decoration: none;
                text-align: right;
                display: block;

                span {
                    font-size: 1.1rem;
                    margin-left: 0.5rem;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .secure-content {
        padding-top: 1rem;
        padding-bottom: 2rem;

        b {
            color: #8DAF61;
        }
    }

}

.help-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background: url("../../../Assets//Home/Background_pink.png") no-repeat center center;
    background-size: cover;
    border-radius: 40px;
    color: white;
    margin-top: 2rem;

    .help-content {
        text-align: left;

        h2 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0.5rem;
            color: white;
        }

        p {
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .help-button {
        .button-link {
            display: inline-block;
            padding: 15px 30px !important;
            background-color: white !important;
            border-radius: 30px !important;
            color: #f75b75 !important;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover {
                background-color: #f75b75 !important;
                color: white !important;
                border: 2px solid white !important;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .programs-content .program-item {
        p {
            font-size: 18px !important;
        }

        h3 {
            font-size: 22px !important;
        }
    }

    .help-section {
        flex-direction: column;

        .help-content {
            text-align: center;
        }
    }

}

@media screen and (max-width: 968px) {
    .programs-section {
        h2 {
            font-size: 28px !important;
        }
    }

    .programs-content {
        flex-direction: column;
        align-items: center;

        .program-item {
            width: 100% !important;

            .learn-more {
                font-size: 16px !important;
            }
        }
    }

    .help-section {
        padding: 20px !important;

        .help-content {
            h2 {
                font-size: 22px !important;
            }

            .help-button {
                .button-link {
                    font-size: 18px !important;
                }
            }

        }
    }
}

@media screen and (max-width: 490px) {
    .programs-section {
        padding-top: 0 !important;

        h2 {
            font-size: 24px !important;
        }
    }
}