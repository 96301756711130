.starter-plan-container {
    text-align: center;
    padding: 2rem;
    padding-top: 120px;

    .section-title {
        font-size: 30px;
        margin-bottom: 1rem;
    }

    .bestPlan-card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        gap: 2rem;
    }

    .bestPlan-details {
        text-align: left;
        width: 50%;
        border: 1px solid #0A0B0A;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;

        .bestPlan {
            padding: 0 1rem 1rem;
        }

        .bestPlan-plan-title {
            font-size: 22px;
            text-align: center;
            color: #8DAF61;
        }

        .bestPlan-title {
            background-color: #8DAF61;
            padding: 1rem;
            border-radius: 10px 10px 0 0;
        }

        .bestPlan-subtitle {
            font-size: 24px;
            margin: 0.5rem 0;
            text-align: center;
        }

        .best-for {
            font-size: 24px;
            font-weight: bold;
            color: #8DAF61;
        }

        ul li {
            margin-bottom: 0.5rem;
            font-size: 20px;
        }

        .bestPlan-price {
            font-size: 38px;
            font-weight: bold;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 1.6;

            .price-currency {
                font-size: 16px;
            }
        }

        .bestPlan-info {
            font-size: 20px;
            margin-top: 0.5rem;
        }
    }

    .bestPlan-includes {
        text-align: left;
        width: 50%;
        border: 1px solid #0A0B0A;
        padding: 1rem;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 10px;

        h3 {
            font-size: 24px;
            margin-bottom: 0.5rem;
            color: #8DAF61;
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                margin-bottom: 1rem;
                font-size: 20px;

                svg {
                    font-size: 20px;
                    font-weight: bold;
                    color: #8DAF61;
                }
            }
        }
    }

    .bestPlan-buttons {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        gap: 1.5rem;

        .secondary-btn {
            &:hover {
                background-color: #8DAF61 !important;
                color: white !important;
            }
        }

        .primary-btn {
            &:hover {
                transition: 0.3s;
            }

            .calendly-button {
                font-size: 20px !important;
                color: white;
                background-color: transparent;
                cursor: pointer;
                border: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .starter-plan-container {
        .section-title {
            font-size: 28px;
        }

        .bestPlan-card {
            flex-direction: column;
            align-items: center;
            border: 1px solid #898483;
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 10px;
            padding: 0;
            gap: 0;
        }

        .bestPlan-details {
            box-shadow: none;
            border: none;
            width: 100%;

            .bestPlan-subtitle,
            .best-for {
                font-size: 22px;
            }
        }

        .bestPlan-includes {
            padding-top: 0;
            box-shadow: none;
            border: none;
            width: 100%;

            h3 {
                font-size: 22px;
                margin: 0;
            }
        }

        .bestPlan-buttons {
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;

            .secondary-btn,
            .primary-btn {
                font-size: 18px !important;
                font-weight: normal !important;
                width: calc(70vw - 5rem);
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 490px) {
    .starter-plan-container {
        .section-title {
            font-size: 24px;
        }

        .bestPlan-details {

            .bestPlan-subtitle,
            .best-for {
                font-size: 20px;

            }

            ul li {
                margin-bottom: 0.5rem;
                font-size: 18px;
            }

        }

        .bestPlan-includes {
            h3 {
                font-size: 20px;
                margin: 0;

            }

            ul li {
                margin-bottom: 0.5rem;
                font-size: 18px;
            }
        }

        .bestPlan-buttons {
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;

            .secondary-btn,
            .primary-btn {
                font-size: 18px !important;
                font-weight: normal !important;
                width: 100%;
                justify-content: center;
            }
        }
    }
}