.testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
    padding: 20px;

    h2 {
        font-size: 36px;
        margin-bottom: 20px;
    }

    .testimonials-wrapper {
        display: flex;
        width: -webkit-fill-available;
        justify-content: space-evenly;
        gap: 2rem;
    }

    .testimonial {
        border-radius: 10px;
        padding: 20px;
        position: relative;
        text-align: left;
        width: 50%;

        .quote-icon {
            font-size: 60px;
            color: #88B04B;
            position: absolute;
            top: 0px;
            padding-bottom: 10px;
        }

        p {
            font-size: 22px;
            color: #333;
            margin-bottom: 10px;
            padding-top: 30px;
        }

        .stars {
            display: flex;
            justify-content: start;
            margin-bottom: 10px;
            gap: 5px;

            .star {
                color: #ddd;
                font-size: 26px;

                &.filled {
                    color: #88B04B;
                }
            }
        }

        h3 {
            font-size: 24px;
            color: #333;
            margin-bottom: 0;
        }

        .position {
            font-size: 18px;
            color: #888;
        }
    }

    .controls {
        display: flex;
        width: -webkit-fill-available;
        margin-top: 20px;
        margin-left: 20px;
        justify-content: flex-start;
        gap: 20px;

        .prev,
        .next {
            background-color: #88B04B;
            border: none;
            border-radius: 50%;
            padding: 7px;
            color: white;
            font-size: 20px;
            width: 35px;
            height: 35px;
            cursor: pointer;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s;

            &:hover {
                transform: scale(1.1);
            }

            &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
                box-shadow: none;
                transform: none;
            }
        }

    }
}

@media screen and (max-width: 968px) {
    .testimonial-container {
        padding: 0;

        h2 {
            font-size: 28px;
        }

        .testimonial {
            padding: 20px 0;

            p {
                font-size: 20px !important;
            }

            h3 {
                font-size: 22px;
            }

            .position {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .testimonial-container .testimonial {
        width: 100%;
    }
}

@media screen and (max-width: 490px) {
    .testimonial-container {
        padding: 2rem !important;
        margin: 0;

        h2 {
            font-size: 24px;
        }

        .testimonial p {
            font-size: 18px !important;
        }

        .controls {
            margin-left: 0;
        }
    }
}