.s2l-headers {
    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        width: 100%;
        box-shadow: 0px 4px 4px 0px rgba(31, 48, 110, 0.15);
        backdrop-filter: blur(12px);
        padding: 1rem 2rem !important;
        min-height: 75px;
        -webkit-backdrop-filter: blur(12px);
        z-index: 999;
        background-color: #FFFFFF;

        .newMenu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            gap: 2rem;

            ul {
                list-style: none;
                display: flex;
                align-items: center;
                gap: 2rem;
                margin: 0;

                li {
                    a {
                        text-decoration: none;
                        color: #000000;
                        font-size: 18px;
                        transition: 0.3s all;

                        &:hover {
                            color: #8DAF61;
                            border-bottom: 2px solid #8DAF61;
                            transform: 0.3s all;
                        }
                    }

                    &.active-newMenu-item a {
                        color: #8DAF61;
                        font-weight: bold;
                    }
                }
            }
        }

        .newMenu-toggle {
            display: none;
            cursor: pointer;

            .bar {
                width: 25px;
                height: 3px;
                background-color: #000000;
                margin: 5px 0;
                transition: all 0.3s ease-in-out;

                &.cross {
                    transform: rotate(45deg);
                    position: relative;
                    top: 9px;
                }

                &:nth-child(2) {
                    opacity: 1;
                }

                &.cross:nth-child(2) {
                    opacity: 0;
                }

                &.cross:nth-child(3) {
                    transform: rotate(-45deg);
                    position: relative;
                    top: -7px;
                }
            }
        }

        @media (max-width: 1008px) {
            .s2l-nav-newMenu {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                background-color: #FFFFFF;
                padding: 1rem !important;
            }

            .newMenu {
                flex-direction: column;
                align-items: center;
                position: relative;
                background-color: #220D50;

                ul {
                    flex-direction: column;
                    display: none;
                }

                .newMenu-toggle {
                    display: block;
                }

                &.show-newMenu {
                    height: 100vh;


                }

                &.show-newMenu ul {
                    display: flex;
                    padding: 0;
                    margin-top: 2rem;

                    li {
                        a {
                            text-decoration: none;
                            color: #FFFFFF;
                            font-size: 18px;
                            transition: 0.3s all;

                            &:hover {
                                color: #8DAF61;
                                border-bottom: 2px solid #8DAF61;
                                transform: 0.3s all;
                            }
                        }

                        &.active-newMenu-item a {
                            color: #8DAF61;
                            font-weight: bold;
                        }
                    }
                }

                .p-button {
                    display: none;
                }

                &.show-newMenu .p-button {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 1008px) {
    .s2l-headers {
        .navigation {
            background-color: #FFFFFF;
            padding: 0 !important;

            .newMenu {
                justify-content: flex-start;
            }
        }
    }
}

@media (max-width: 490px) {
    .s2l-headers .navigation .newMenu .logo img {
        width: 220px;
    }
}