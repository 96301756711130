.stack-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    border-bottom: 5px solid rgba(5, 41, 65, 0.5);


    .p-overlaypanel-content {
        ul.profile-menu-list {
            list-style: none !important;
        }
    }

    .menu-items {
        display: flex;
        align-items: center;
        gap: 35px;
        margin: 0;
        padding: 0;

        .profileIcon {
            display: flex;
            align-items: center;
            gap: 3px;
            cursor: pointer;

            svg:first-child {
                width: 40px;
                height: 40px;
            }
        }
    }

}

ul.profile-menu-list {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    cursor: pointer;
    line-height: 2;

    li {
        gap: 10px;
        display: flex;
        align-items: center;
        padding: 5px;
    }
}

.container {
    width: 100%;
    margin: 0 auto;
    z-index: 999;
    display: flex;

    .left-bar {
        width: 320px;
        max-width: 275px;
        // background: #FEEEDF;
        height: 100vh;

        .logo {
            text-align: center;
            padding: 1rem 0;

            img {
                width: 255px;
                height: 100%;
            }
        }

        .avatar-section {
            display: grid;
            justify-content: center;
            align-items: center;

            .student-name {
                font-size: 26px;
                text-align: center;
                padding: 0.5rem 0;
            }
        }

        .menu-items {

            ul {
                padding-left: 0;
                cursor: pointer;

                li {
                    list-style: none;
                    padding: 20px;
                    border-bottom: 1px solid #BFBFBF;
                }
                .active {
                    background-color: #F9AB60;
                    font-weight: bold;         /* Optional: make the active item bold */
                }
            }
        }
    }

    .header {
        // border-bottom: 5px solid rgba(5, 41, 65, 0.5);
        height: auto;
        box-shadow: 0 6px 8px rgba(0, 0, 0, .10196078431372549);
        background: hsla(0, 0%, 100%, .7803921568627451);
        top: 0;
        z-index: 10;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        min-height: 90px;
        height: 90px;
        width: -webkit-fill-available;
        position: sticky;
        top: 0;
        height: 100vh;
        overflow-y: hidden;
        /* Allows the sidebar itself to scroll if necessary */

        .menu-content-section {}
    }

}

// .p-overlaypanel {
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
//     border-radius: 10px !important;
// }

// .p-overlaypanel:after,
// .p-overlaypanel:before {
//     display: none !important;
// }

.hamburger-menu {
    display: none;
}

.bar {
    width: 25px;
    height: 5px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
}

.bar.active:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.bar.active:nth-child(2) {
    opacity: 0;
}

.bar.active:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

.right-content {
    display: none;
}

.mobile-header {
    display: none;
}

.topics-back-button {
    background-color: white !important;
    border: none !important;
    font-size: 16px;
    align-items: center;
    margin: 10px;
}

.menu-item-icon {
    margin-right: 6px;
}