.s2l-academy-aboutus {
    padding-top: 150px;
    height: 600px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .aboutus-banner {
        height: 80%;
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .blur-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #a2a7f1;
            filter: blur(80px);
            z-index: 1;
        }

        h2 {
            position: relative;
            z-index: 2;
            margin: 0px;
            font-size: 42px;
            font-weight: 700;
            line-height: 1.5;
            text-align: center;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 1024px) {
    .s2l-academy-aboutus {
        height: 400px;

        .aboutus-banner h2 {
            font-size: 40px;
            line-height: 1.4;
        }
    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-aboutus .aboutus-banner h2 {
        font-size: 32px;
        line-height: 1.6;

    }
}

@media screen and (max-width: 520px) {
    .s2l-academy-aboutus {
        height: 340px;
        .aboutus-banner h2 {
            font-size: 28px;
            line-height: 1.4;
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-aboutus {
        height: 450px;

        .aboutus-banner h2 {
            font-size: 26px;
            line-height: 1.4;
        }
    }
}