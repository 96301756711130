.notes-container {
  padding: 2rem;
  max-height: 87vh; // Adjust this value as needed
  overflow-y: auto; // Enable vertical scrolling
  scrollbar-width: none;
  padding: 2rem;
  max-height: 87vh; // Adjust this value as needed
  overflow-y: auto; // Enable vertical scrolling
  scrollbar-width: none;
}

.mynotes-title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 87%;
  /* 27.84px */
  letter-spacing: -0.96px;
  margin-bottom: 1rem;
}

.add-notes-section {
  display: flex;
  justify-content: flex-end;

  .add-note-containers {
    padding: 0 0.5rem;
    width: 250px;
  }
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 87%;
  /* 27.84px */
  letter-spacing: -0.96px;
  margin-bottom: 1rem;
}

.mynotes {
  padding: 2rem 0rem;
  padding: 2rem 0rem;
}

.note-courses {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.note-course-type {
  font-size: 14px;
  border-radius: 24px;
  border: 2px solid var(--Chip-Stroke-Color, #433f3e);
  padding: 10px;
  font-size: 14px;
  border-radius: 24px;
  border: 2px solid var(--Chip-Stroke-Color, #433f3e);
  padding: 10px;
}



.notes-container {
  .add-notes-section {
    .add-note-containers {
      .clear-button {
        background: #c02929 !important;
        border-color: #c02929 !important;
      }
    }
  }
}

#pr_id_19{
  .p-dialog-header{
    padding: 1rem !important;
  }

  #pr_id_19_content{
    padding: 2rem 1.5rem 2rem 1.5rem !important;
  }

  .p-dialog-footer{
    padding: 1rem !important;
  }

}

.p-editor-toolbar{
  display: none;
}

.custom-message {
  padding: 2rem 1.5rem 0 1.5rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 1008px) {
  .notes-container {
    .add-notes-section {
      .add-note-containers {
        flex: 1;
      }
    }
  }
}

@media (max-width: 620px) {
  .notes-container {
    .mynotes-title {
      //   display: none;
      text-align: center;
    }
    .add-notes-section {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .add-note-containers {
        width: 100%;
      }
    }
  }
}

@media (max-width: 490px) {
  .notes-container {
    .mynotes-title {
      display: none;
    }
  }

  .edit-dialog {
    width: 90vw !important;
    .p-dialog-content {
      scrollbar-width: none !important;
      padding: 0.8rem !important;
    }
    .edit-note {
      .grid {
        .field {
          margin-bottom: 0rem !important;
          label {
            margin-bottom: 0rem !important;
          }
        }
      }

      .edit-save-btn {
        Button {
          width: 100%;
        }
      }
    }
  }
}
