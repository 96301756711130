.s2l-academy-ourvalues {
    padding: 20px 60px;

    .ourvalues-container {
        h2 {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
            margin-top: 0px;
        }

        .about-our-values-list .about-our-values-item {
            width: 24%;
            margin: 1%;

            .value-img-lg {
                display: block;
            }

            .value-title-sec {
                .value-img-sm {
                    display: none;
                }
            }

            h3 {
                font-size: 24px;
                font-weight: 700;
                line-height: 1.2;
            }

            p {
                font-size: 20px;
                line-height: 1.4;
            }
        }
    }
}



@media screen and (max-width: 1200px) {
    .s2l-academy-ourvalues {
        padding: 20px 40px !important;

        .ourvalues-container {
            h2 {
                font-size: 32px;
            }

            .about-our-values-list {
                flex-direction: row !important;
                flex-wrap: wrap;

                .about-our-values-item {
                    width: 49% !important;
                    margin: 0% 1% 5% 0% !important;

                    .value-img-lg {
                        display: none;
                    }

                    .value-title-sec {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 24px;
                        }

                        .value-img-sm {
                            display: block;
                            height: 50px;
                            width: 50px;
                            object-fit: contain;
                        }
                    }

                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-ourvalues {
        padding: 20px 40px !important;

    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-ourvalues {
        padding: 20px 30px !important;

        .ourvalues-container {
            h2 {
                font-size: 28px;
            }

            .about-our-values-list {
                flex-direction: row !important;
                flex-wrap: wrap;

                .about-our-values-item {
                    width: 99% !important;
                    margin: 0% 1% 5% 0% !important;

                    .value-img-lg {
                        display: none;
                    }

                    .value-title-sec {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h3 {
                            font-size: 24px;
                        }

                        .value-img-sm {
                            display: block;
                            height: 50px;
                            width: 50px;
                            object-fit: contain;
                        }
                    }

                    p {
                        font-size: 18px;

                    }
                }
            }
        }
    }
}


@media screen and (max-width: 520px) {
    .s2l-academy-ourvalues {
        padding: 20px 20px !important;
    }
}