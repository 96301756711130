.s2l-academy-ourteam {
    padding: 20px 60px;

    .our-team-container {
        .our-team-header {
            h2 {
                text-align: center;
                font-size: 32px;
                font-weight: 700;
                line-height: 1.4;
            }

            p {
                font-size: 20px;
                line-height: 1.6;
                text-align: center;
                font-weight: 400;
            }
        }

        .exe-advisor-heading {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.4;
        }

        .our-team-members,
        .our-team-executive-members {
            .team-member {
                width: 15.666%;
                margin-right: 1%;

                .member-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 200px;
                        width: 200px;
                        object-fit: cover;
                    }
                }

                h3 {
                    font-size: 22px;
                    padding: 10px;
                    line-height: 1.4;
                    font-weight: 700;
                    margin: 0px;
                    text-align: center;
                }

                p {
                    margin: 0px;
                    font-size: 18px;
                    line-height: 30px;
                    color: #9f9c9c;
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .s2l-academy-ourteam {

        .our-team-container .our-team-members,
        .our-team-executive-members {
            .team-member {
                .member-img {
                    img {
                        height: 170px !important;
                        width: 170px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-academy-ourteam {
        padding: 40px !important;

        .our-team-container .our-team-members,
        .our-team-executive-members {
            .team-member {
                width: 32% !important;
                margin-right: 1% !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .s2l-academy-ourteam {
        padding: 30px !important;

        .our-team-container .our-team-members,
        .our-team-executive-members {
            gap: 0px !important;

            .team-member {
                width: 48% !important;
                margin-right: 2% !important;
                padding: 30px !important;

                .our-team-container .our-team-members,
                .our-team-executive-members {
                    gap: 0px !important;

                    .team-member {
                        width: 48% !important;
                        margin-right: 2% !important;

                        .member-img img {
                            height: 170px !important;
                            width: 170px !important;

                            .member-img img {
                                height: 170px !important;
                                width: 170px !important;
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .s2l-academy-ourteam {
        padding: 70px 20px 20px 20px !important;
        padding: 70px 20px 20px 20px !important;

        .our-team-container {
            p {
                font-size: 18px !important;
            }

            p {
                font-size: 18px !important;
            }

            .our-team-header h2 {
                font-size: 28px;
                margin: 0px;
            }

            .exe-advisor-heading {
                font-size: 28px;
            }

            .team-member {
                width: 48% !important;
                margin-right: 2% !important;

                h3 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 18px !important;
                    line-height: 1.4 !important;
                }

                .member-img img {
                    height: 150px !important;
                    width: 150px !important;
                }

            }
        }
    }
}

@media screen and (max-width: 390px) {

    .s2l-academy-ourteam .our-team-container {

        .our-team-members,
        .our-team-executive-members {
            .team-member {
                width: 100% !important;
                margin-right: 0% !important;

                .member-img img {
                    height: 150px !important;
                    width: 150px !important;

                }

            }
        }

    }
}