.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem 2rem 2rem;
    padding-top: 9rem;

    .banner-content {
        flex: 1;
        max-width: 50%;
    }

    h1 {
        color: #000;
        font-size: 50px;
        font-weight: 700;
        margin: 0 0 20px 0;
    }

    p {
        font-size: 24px;
        line-height: 126%;
    }

    .banner-images {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4rem 0;

        .background-image {
            width: 100%;
            height: auto;
            position: absolute;
            left: 0;
            z-index: 1;
        }

        .video-container {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            width: 70%;

            .thumbnail {
                position: absolute;
                max-width: 100%;
                height: auto;
                border: 3px solid #ffc500;
            }

            video {
                max-width: 100%;
                height: auto;
                border: 3px solid #ffc500;
            }

            .play-pause-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 1.8em;
                color: white;
                background: rgba(0, 0, 0, 0.5);
                border: none;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                padding: 25px;
                display: none;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                outline: none;
            }
        }

        .video-container:hover .play-pause-button {
            display: flex;
        }

        .play-pause-button.show {
            display: flex;
        }
    }
}

@media screen and (max-width: 1200px) {
    .layout {
        h1 {
            font-size: 36px;

            span {
                font-size: 36px;
            }
        }

        p {
            font-size: 22px;

            span {
                font-size: 22px;
            }
        }

        span {
            font-size: 22px;
        }
    }

    .banner-section {
        margin-top: 110px !important;
    }

    .banner-section-content {
        max-width: 500px;
    }
}

@media (max-width: 887px) {
    .layout {
        padding-top: 8rem !important;
        padding: 0;
        flex-direction: column;

        .banner-content {
            max-width: 100%;
            width: 100% !important;
            padding: 0 2rem 0 2rem;
        }

        .banner-section-content {
            max-width: 100%;
        }

        h1 {
            margin: 0;
            font-size: 32px;

            span {
                font-size: 32px;
            }
        }

        p {
            font-size: 18px !important;

            span {
                font-size: 18px !important;
            }
        }

        .banner-button {
            margin: 2.2rem 0 !important;
        }

        .banner-images {
            max-width: 100%;
        }

        .video-container {
            width: 80% !important;
        }
    }
}

@media screen and (max-width: 490px) {
    .layout {
        padding-top: 7rem !important;
        padding: 0;

        h1 {
            font-size: 26px;

            span {
                font-size: 26px;
            }
        }

        .banner-button {
            margin: 2.2rem 0 0 !important;
        }

        span {
            font-size: 20px;
        }

        .video-container .play-pause-button {
            top: 43% !important;
        }
    }

    .banner-section-content {
        max-width: 100% !important;
        padding: 0 !important;
    }
}