.entrepreneurship-journey {
    text-align: center;
    padding: 20px;
    padding-top: 120px;

    h2 {
        font-size: 32px;
    }

    p {
        font-size: 24px;
        margin: 10px 0;
    }

    h4 {

        font-size: 20px;
        color: #83869C;
    }

    .questions-container {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        flex-wrap: wrap;
        border: 1px solid #B5B3B3B2;
        border-radius: 20px;
        margin: 2rem;
        box-shadow: 4px 4px 4px 0px #00000040;
        padding: 2rem;
        padding-top: 4rem;

        .question-box {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 20px;
            padding-top: 0;
            width: 18%;
            text-align: center;
            margin: 10px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
            box-shadow: 3px 4px 4px 0px #00000040;

            &.highlighted {
                border-left: 10px solid #220D50;
                color: #000000;

                .question-icon {
                    color: #220D50;
                }

                .question-number {
                    color: #220D50;
                    padding: 0.5rem;
                }
            }

            .question {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                top: -30px;
            }

            .question-number {
                font-size: 3rem;
                font-weight: bold;
                color: #D0D3EA;
            }

            .question-icon {
                font-size: 5rem;
                color: #D0D3EA;
            }

            h3 {
                font-size: 22px;
            }

            p {
                font-size: 20px;
            }

        }
    }

    .why-it-matters {
        font-size: 1rem;
        color: #888;
        margin-bottom: 20px;
    }

    .continue-button {
        padding: 10px 20px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #45a049;
        }
    }
}

@media screen and (max-width: 1400px) {
    .entrepreneurship-journey {
        .questions-container {
            padding: 0;
            padding-top: 4rem;
            padding-bottom: 3rem;
            margin: 0.5rem;

            .question-box {
                .question {
                    .question-number {
                        font-size: 3rem;
                    }

                    .question-icon {
                        font-size: 4rem;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 1200px) {
    .entrepreneurship-journey {
        .questions-container {
            display: inline-block;

            .question-box {
                width: 60%;
                margin: 2rem 0;

                &:nth-child(odd) {
                    float: left;
                }

                &:nth-child(even) {
                    float: right;
                }

                &.highlighted {
                    &:nth-child(odd) {
                        border-right: none;
                    }

                    &:nth-child(even) {
                        border-right: 10px solid #220D50;
                        border-left: none;
                        color: #220D50;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 490px) {
    .entrepreneurship-journey {
        h2 {
            font-size: 28px;
        }

        p {
            font-size: 20px;
        }

        .questions-container {
            .question-box {
                width: 80%;
            }
        }
    }
}