.plan-selector {
    .programs-selector {
        width: 100%;
        justify-content: center;
        display: flex;
        // padding: 3rem 0rem;
        padding-top: 3rem;

        .programs-students,
        .programs-enterpreneurs {
            border: 3px solid #1a0a3d;
            padding: 1rem 0rem;
            width: 377px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
        }

        .active {
            background-color: #1a0a3d;
            color: white;
        }
    }

    .nda-desc {
        padding: 1.5rem 6rem;
    }

    .display-programs-container {
        padding: 0rem 4rem;
        display: flex;


        .program-container {
            flex: 1;
            padding: 0rem 1rem;

            .program-section {
                border-radius: 11px;
                border: 1px solid #B5B3B3;
                box-shadow: 0px 4px 4px 0px #00000040;

                .cap {
                    height: 55px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    position: relative;
                    display: flex;
                    justify-content: end;
                    align-items: center;

                    .cf-program {
                        color: white;
                        padding: 0rem 1rem;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        height: 30px;
                        width: auto;
                        vertical-align: baseline;
                        cursor: pointer;

                        i {

                            text-decoration: none !important;
                            font-size: 14px;
                        }

                        p {
                            text-decoration: underline;
                            font-size: 13px !important;
                        }
                    }
                }

                .p-sec-one {
                    display: flex;
                    flex-direction: column;
                    padding: 0rem 2rem;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    height: 160px;


                    .program-number {
                        margin: 0;
                    }

                    .program-title {
                        text-align: center;
                        margin: 0;
                    }
                }

                .p-sec-two {
                    padding: 0rem 2rem;
                    flex: 1;

                    .sec-two-student-height {
                        height: 230px;
                    }

                    .sec-two-entrepreneurs-height {
                        height: 190px;
                    }

                    .best-points-container {
                        ul {
                            li {
                                font-size: 20px;
                                margin-bottom: 5px;
                                line-height: 1.4;
                            }
                        }
                    }

                    .p-pricing {
                        text-align: center;
                        padding: 1rem 0rem;

                        .calendly-button {
                            color: white;
                            border: none;
                            background-color: transparent;
                            font-size: larger;
                            cursor: pointer;
                        }

                        .payment {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;

                            .p-amount {
                                margin: 0;
                            }


                        }

                        p {
                            .pi {
                                border-color: lightgray;
                                margin-left: 5px;
                                height: 10px;
                                width: 10px;
                                font-size: medium;
                                color: white;
                                border-radius: 50%;
                                padding: 3px;
                                background-color: lightgray;
                            }
                        }



                    }
                }

                .p-sec-three {
                    padding: 0rem 2rem;

                    .sec-three-student-height {
                        height: 610px;
                    }

                    .sec-three-entrepreneurs-height {
                        height: 560px;
                    }

                    .include-points {
                        // text-align: justify;
                        padding-top: 0.5rem;

                        .include-each-points {
                            display: flex;
                            gap: 8px;
                            vertical-align: baseline;
                            margin-bottom: 10px;

                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 20px;
                                width: 28px;
                                padding: .5rem;
                                font-weight: 900;
                                font-size: 20px;
                            }

                            .point-details {
                                font-size: 18px;

                                .point-content {
                                    line-height: 1.4;
                                }
                            }
                        }
                    }
                }

                .p-sec-four {
                    padding: .5rem 1rem;
                    height: 220px;
                    margin: 0rem 2rem;
                    color: white;

                    h3 {
                        margin: 0;
                        color: white;
                    }

                    p {
                        font-size: 18px;
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    .accordian-programs {
        display: none;
    }
}

.stake-dialog {
    // width: 90%;

    .p-dialog-header {
        background-color: white !important;
    }

    .dialog-header {
        border-radius: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #352750;
        padding: 1rem;

        .p-dialog .p-dialog-header {
            background: #FFFFFF !important;
        }
    }

    .stake-dialog-body {
        p {
            text-align: left;
            padding-left: 1rem;
        }

        .Our-Philosophy {
            .p {
                text-align: left;
            }
        }
    }
}

.overlay-pannel {
    width: 500px;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;

    &::before {
        margin-left: 30px !important;
    }

    &::after {
        margin-left: 30px !important;
    }

}



@media (max-width:1700px) {

    .plan-selector {
        .display-programs-container {
            .program-container {
                .program-section {


                    .p-sec-one {
                        height: 180px !important;
                    }

                    .p-sec-two {

                        .sec-two-student-height {
                            height: 225px !important;
                        }

                        .sec-two-entrepreneurs-height {
                            height: 200px !important;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 18px !important;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                    }

                    .p-sec-three {
                        padding: 0rem 2rem;

                        .sec-three-student-height {
                            height: 830px !important;
                        }

                        .sec-three-entrepreneurs-height {
                            height: 660px !important;
                        }

                        .include-points {
                            .include-each-points {
                                .point-details {
                                    font-size: 18px;

                                    .point-content {
                                        line-height: 23px;
                                    }
                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: 0rem 2rem;

                        h3 {
                            margin: 0;
                        }

                        p {
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }

                    .sec-four-student-height {
                        height: 260px !important;
                    }

                    .sec-four-entrepreneurs-height {
                        height: 250px !important;
                    }
                }
            }
        }
    }

    .overlay-pannel {
        width: 450px;
    }


}

@media (max-width:1440px) {

    .plan-selector {

        .nda-desc {
            p {
                font-size: 15px !important;
            }
        }

        .display-programs-container {
            .program-container {
                .program-section {

                    .p-sec-one {
                        height: 200px !important;
                    }

                    .p-sec-two {
                        padding: 0 1rem !important;

                        .sec-two-student-height {
                            height: 310px !important;
                        }

                        .sec-two-entrepreneurs-height {
                            height: 230px !important;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 18px !important;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .p-pricing {
                            p {
                                font-size: 17px;
                            }
                        }

                    }

                    .p-sec-three {
                        padding: 0rem 1rem !important;

                        .sec-three-student-height {
                            height: 890px !important;
                        }

                        .sec-three-entrepreneurs-height {
                            height: 700px !important;
                        }

                        .include-points {
                            .include-each-points {
                                .point-details {
                                    font-size: 18px;

                                    .point-content {
                                        line-height: 23px;
                                    }
                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: .5rem 1rem !important;
                        margin: 0rem 1rem !important;

                        h3 {
                            margin: 0;
                        }

                        p {
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }

                    .sec-four-student-height {
                        height: 280px !important;
                    }

                    .sec-four-entrepreneurs-height {
                        height: 250px !important;
                    }

                }
            }
        }
    }

    .overlay-pannel {
        width: 350px;
        padding: .5rem;

        p {
            font-size: 14px !important;
        }
    }


}

@media (max-width:1200px) {

    .plan-selector {
        .nda-desc {
            padding: 1.5rem 4rem;

            p {
                font-size: 13px !important;
            }
        }

        .display-programs-container {
            padding: 0rem 2rem !important;


            .program-container {
                .program-section {

                    .p-sec-one {
                        gap: .5rem !important;
                        height: 160px !important;
                        padding: 0rem .5rem !important;

                        .program-title {
                            font-size: 20px;
                        }
                    }

                    .p-sec-two {
                        padding: 0rem 1rem !important;

                        .sec-two-student-height {
                            height: 300px !important;
                        }

                        .sec-two-entrepreneurs-height {
                            height: 220px !important;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 17px !important;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .p-pricing {
                            text-align: center;
                            padding: 1rem 0rem;

                            .payment {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;

                                .p-amount {
                                    margin: 0;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .p-sec-three {
                        padding: 0rem 1rem !important;

                        .sec-three-student-height {
                            height: 880px !important;
                        }

                        .sec-three-entrepreneurs-height {
                            height: 770px !important;
                        }

                        .include-points {
                            // text-align: justify;
                            padding-top: 0.5rem;

                            .include-each-points {
                                display: flex;
                                gap: 8px;
                                vertical-align: baseline;
                                margin-bottom: 10px;

                                span {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 20px;
                                    width: 28px;
                                    padding: .5rem;
                                    font-weight: 900;
                                    font-size: 20px;
                                }

                                .point-details {
                                    font-size: 17px !important;

                                    .point-content {
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: 0rem 2rem;

                        h3 {
                            margin: 0;
                        }

                        p {
                            font-size: 17px !important;
                            line-height: 25px;
                        }
                    }

                    .sec-four-student-height {
                        height: 315px !important;
                    }

                    .sec-four-entrepreneurs-height {
                        height: 285px !important;
                    }
                }
            }
        }
    }

    .overlay-pannel {
        width: 300px;
        padding: .5rem;

        p {
            font-size: 13px !important;
        }
    }

}


@media (max-width:1008px) {
    .plan-selector {
        .programs-selector {
            width: 100%;
            justify-content: center;
            display: flex;
            // padding: 2rem 0rem !important;
            padding-top: 2rem;

            .programs-students,
            .programs-enterpreneurs {
                padding: 0.5rem 0rem !important;
                width: 250px !important;
                font-size: 16px !important;
                font-weight: 600;
                align-content: center;
            }
        }

        .nda-desc {
            padding: 1rem 4rem;

            p {
                font-size: 12px !important;
            }
        }

        .display-programs-container {
            display: none;
        }

        .accordion-header {

            .header-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                font-size: 22px;
            }

            .arrow-icon {
                font-size: 20px;
                transition: transform 0.3s ease;

                i {
                    font-size: larger;
                }
            }

            .arrow-up {
                transform: rotate(90deg);
            }

            .arrow-down {
                transform: rotate(0deg);
            }
        }

        .accordian-programs {
            display: block;
            padding: 0rem 2rem;
            margin-bottom: 2rem !important;

            .program-container {
                // background-color: aquamarine;
                display: flex;
                justify-content: center;
                padding: 1rem 0rem;

                .program-section {
                    border-radius: 11px;
                    border: 1px solid #b5b3b3;
                    width: 400px;
                    box-shadow: 0px 4px 4px 0px #00000040;

                    .cap {
                        height: 55px;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        position: relative;
                        display: flex;
                        justify-content: end;
                        align-items: center;

                        .cf-program {
                            color: white;
                            padding: 0rem .5rem;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            height: 30px;
                            width: auto;
                            vertical-align: baseline;
                            cursor: pointer;

                            i {

                                text-decoration: none !important;
                                font-size: 14px;
                            }

                            p {
                                text-decoration: underline;
                                font-size: 12px !important;
                            }
                        }


                    }

                    .p-sec-one {
                        display: flex;
                        flex-direction: column;
                        padding: 0rem 2rem;
                        align-items: center;
                        justify-content: center;
                        gap: 1rem;
                        height: 200px !important;


                        .program-number {
                            margin: 0;
                        }

                        .program-title {
                            text-align: center;
                            margin: 0;
                        }
                    }

                    .p-sec-two {
                        padding: 0rem 2rem;
                        flex: 1;

                        .sec-two-student-height {
                            height: 200px;
                        }

                        .sec-two-entrepreneurs-height {
                            height: 150px;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 20px;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .p-pricing {
                            text-align: center;
                            padding: 1rem 0rem;

                            .calendly-button {
                                color: white;
                                border: none;
                                background-color: transparent;
                                font-size: larger;
                            }

                            .payment {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;

                                .p-amount {
                                    margin: 0;
                                }
                            }

                            p {
                                .pi {
                                    border-color: lightgray;
                                    margin-left: 5px;
                                    height: 10px;
                                    width: 10px;
                                    font-size: medium;
                                    color: white;
                                    border-radius: 50%;
                                    padding: 3px;
                                    background-color: lightgray;
                                }
                            }


                        }
                    }

                    .p-sec-three {
                        padding: 0rem 2rem;

                        .sec-three-student-height {
                            height: 650px;
                        }

                        .sec-three-entrepreneurs-height {
                            height: 530px;
                        }

                        .include-points {
                            // text-align: justify;
                            padding-top: 0.5rem;

                            .include-each-points {
                                display: flex;
                                gap: 8px;
                                vertical-align: baseline;
                                margin-bottom: 10px;

                                span {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 20px;
                                    width: 28px;
                                    padding: .5rem;
                                    font-weight: 900;
                                    font-size: 20px;
                                }

                                .point-details {
                                    font-size: 18px;

                                    .point-content {
                                        line-height: 25px;
                                    }
                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: 1rem 2rem;
                        // height: 180px;
                        color: white;

                        h3 {
                            margin: 0;
                        }

                        p {
                            font-size: 18px !important;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:768px) {
    .plan-selector {
        .programs-selector {

            .programs-students,
            .programs-enterpreneurs {
                padding: 0.5rem 0rem !important;
                width: 180px !important;
                font-size: 14px !important;
            }
        }

        .nda-desc {
            padding: .5rem 3rem;
        }

        .display-programs-container {
            display: none;
        }

        .accordion-header {

            .header-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                font-size: 20px;
            }

            .arrow-icon {
                font-size: 20px;
                transition: transform 0.3s ease;

                i {
                    font-size: large;
                }
            }

            .arrow-up {
                transform: rotate(90deg);
            }

            .arrow-down {
                transform: rotate(0deg);
            }
        }

        .accordian-programs {

            margin-bottom: 2rem !important;

            .program-container {
                display: flex;
                justify-content: center;
                padding: 1rem 0rem;

                .program-section {


                    .p-sec-one {
                        padding: 1.5rem 1rem;
                        gap: .5rem;
                        height: auto !important;

                        .program-number {
                            font-size: 16px;
                        }

                        .program-title {
                            font-size: 24px;
                        }

                    }

                    .p-sec-two {
                        padding: 0rem 1rem;

                        h3 {
                            font-size: 20px;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 18px;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .p-pricing {
                            padding: 0rem;

                            .payment {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;

                                .p-amount {
                                    margin: 0;
                                    font-size: 18px;
                                }
                            }
                        }
                    }

                    .p-sec-three {
                        padding: 0rem 1rem;

                        h3 {
                            font-size: 20px;
                        }

                        .include-points {
                            padding-top: 0.5rem;

                            .include-each-points {
                                display: flex;
                                gap: 8px;
                                vertical-align: baseline;
                                margin-bottom: 10px;

                                .point-details {
                                    font-size: 18px;

                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: 1rem 1rem;
                        height: auto;

                        h3 {
                            font-size: 20px !important;
                        }

                        p {
                            font-size: 18px !important;
                            line-height: 25px
                        }
                    }
                }
            }
        }
    }

    .stake-dialog {
        .dialog-header {
            padding: 0rem;

            img {
                display: none;
            }

        }


    }


}

@media (max-width:490px) {


    .plan-selector {
        .programs-selector {

            .programs-students,
            .programs-enterpreneurs {
                padding: 0.5rem 0rem !important;
                width: 150px !important;
                font-size: 16px !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .nda-desc {
            padding: .5rem 2rem;
        }

        .display-programs-container {
            display: none;
        }

        .accordion-header {
            .header-content {
                font-size: 20px !important;
            }
        }

        .event-content-button {
            padding: 0rem !important;
        }

        .accordian-programs {

            margin-bottom: 0rem !important;
            padding: 0rem 1rem !important;

            .p-accordion {
                .p-accordion-content {
                    padding: 1rem 0rem !important;
                }


            }

            .program-container {
                padding: .5rem 0rem;

                .program-section {
                    width: 300px;

                    .cap {
                        height: 40px;

                    }

                    .p-sec-one {
                        padding: 2rem 1rem;

                        .program-number {
                            font-size: 16px;
                        }

                        .program-title {
                            font-size: 22px;
                        }

                    }

                    .p-sec-two {
                        padding: 0rem 1rem;

                        h3 {
                            font-size: 20px;
                        }

                        .best-points-container {
                            ul {
                                li {
                                    font-size: 18px !important;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .p-pricing {
                            padding: 0rem;

                            Button {
                                font-size: 18px !important;
                            }

                            .payment {
                                font-size: 12px;

                                .p-amount {
                                    font-size: 26px !important;
                                }
                            }

                            p {
                                font-size: 16px !important;
                            }
                        }
                    }

                    .p-sec-three {
                        padding: 0rem 1rem;

                        h3 {
                            font-size: 20px;
                        }

                        .include-points {
                            padding-top: 0.5rem;

                            .include-each-points {
                                gap: 5px;
                                margin-bottom: 8px;

                                .point-details {
                                    font-size: 18px;

                                }
                            }
                        }
                    }

                    .p-sec-four {
                        padding: 1rem 1rem;
                        height: auto;

                        h3 {
                            font-size: 20px !important;
                        }

                        p {
                            font-size: 18px !important;
                        }
                    }
                }
            }


        }
    }

    .stake-dialog {
        .dialog-header {
            justify-content: center;

            h2 {
                font-size: 24px;
            }

        }
    }

    .overlay-pannel {
        width: 240px;
        padding: 0rem .5rem;

        p {
            font-size: 11px !important;
        }
    }


}