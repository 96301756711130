.s2l-academy-programs {
    padding: 60px;
    background-color: #feeedf;

    .program-container {
        .program-header {
            h2 {
                font-size: 32px;
                line-height: 1.4;
                font-weight: 700;
            }

            p {
                color: #433f3e;
                font-size: 20px;
                line-height: 1.6;
                font-weight: 500;
            }
        }

        .program-list {
            column-gap: 2%;

            #pc_1 {
                border-left: 30px solid #ef5064;
            }

            #pc_2 {
                border-left: 30px solid #f9ab60;
            }

            #pc_3 {
                border-left: 30px solid #000000;
            }

            .col-4 {
                padding: 2rem !important;
                width: 32% !important;
            }

            .program-card {
                background-color: #ffff;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 15px;

                h3 {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 1.2;
                    margin: 0px;
                }

                p {
                    color: #433f3e;
                    font-size: 20px;
                    line-height: 1.6;
                    font-weight: 400;
                    margin: 0px;
                }
            }
        }

        .program-button {
            display: flex;
            justify-content: center;
        }
    }
}

@media screen and (max-width:1440px) {
    .s2l-academy-programs {
        padding: 50px;
    }
}

@media screen and (max-width: 1199px) {
    .s2l-academy-programs {
        .program-container {
            .program-header {
                h2 {
                    font-size: 32px;
                    line-height: 1.2;
                }

                p {
                    font-size: 20px;
                }
            }

            .program-list {
                .col-4 {
                    width: 32% !important;
                    margin-bottom: 20px;
                }
            }

            .program-button {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .s2l-academy-programs .program-container {
        .program-list {
            .col-4 {
                width: 100% !important;
                margin-bottom: 20px;
            }
        }

        .program-button {
            display: flex;
            justify-content: center;
        }
    }

}

@media screen and (max-width: 920px) {
    .s2l-academy-programs {
        padding: 30px;

        .program-container {
            .program-header {
                h2 {
                    font-size: 28px;
                    line-height: 1.2;
                }

                p {
                    font-size: 20px;
                }
            }

            .program-list {

                .program-card {
                    h3 {
                        font-size: 22px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .s2l-academy-programs {
        padding: 20px;

        .program-container {
            .program-header {
                h2 {
                    font-size: 24px;
                    line-height: 1.2;
                }

                p {
                    font-size: 18px;
                }
            }

            .program-list {
                .col-4 {
                    width: 100% !important;
                    margin-bottom: 20px;
                }

                .program-card {
                    h3 {
                        font-size: 22px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }

            .program-button {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .s2l-academy-programs {
        padding: 15px 15px 40px 15px;

        .program-container {
            gap: 1rem !important;
        }
    }
}