.customize-chatbot-dialog {
  .customize-profile-avatar {
    width: 6rem;
    height: 6rem;
    background: #eee;
    border-radius: 50%;
    padding: 12px;
  }

  .border-line {
    border: 1px solid #eee;
    margin: 0 10px;
  }

  .customize-profile-avatar-section {
    background: #eee;
    border-radius: 50%;
    padding: 4px;
  }
}


@media (max-width: 768px) {
  .customize-chatbot-dialog {
    width: 85vw !important;

    .cu_cb_profile {
      flex-direction: column !important;
      gap: 15px;
    }

    .text-overlay {
      padding: 10px !important;

      .bubble {
        max-width: 70% !important;

        .p-avatar.p-avatar-lg {
          width: 6rem !important;
        }
      }

      .user-message {
        bottom: 133px !important;
        padding: 10px;
        margin: 0px !important;
      }
    }

    .customize_cb_style {
      flex-direction: column;

      #fontColor {
        input {
          width: -webkit-fill-available;
        }
      }

      #bubbleColor {
        input {
          width: -webkit-fill-available;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .customize-chatbot-dialog {
    width: 90vw !important;

    .text-overlay {
      padding: 10px !important;

      .bubble {
        max-width: 100% !important;

        .p-avatar.p-avatar-lg {
          width: 8rem !important;
        }
      }

      .user-message {
        bottom: 133px !important;
        padding: 10px;
        margin: 0px !important;
      }
    }

    .cu_cb_profile {
      flex-direction: column !important;
      gap: 15px;
    }
  }
}