.valid {
    color: #036E35;
}

.invalid {
    color: #EF5454;
}

.auth-section {
    width: 100%;

    .errorLable {
        height: 30px;
        font-size: 14px;
        color: #E04A4A;
        display: flex;
        align-items: center;
    }

    .resend {
        height: 5vh;
        display: flex;
        justify-content: end;
        align-items: center;
        color: #052941;
        cursor: pointer;

        span {
            cursor: pointer;
        }
    }


    .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
            padding: 20px;
            width: 300px;
        }
    }

    .signup-card-section {
        .image-field {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
            }
        }

        .image-field-sec {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            height: 70vh;

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
            }
        }

        .form-container {
            height: 85vh;
            display: flex;
            justify-content: center;
            align-items: center;

            .account {
                width: 100%;
            }

            p {
                font-size: 24px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 11px;
            }

            .heading-content {
                p {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #808080;
                }
            }

            label {
                font-size: 16px;
                color: #000000;

                ::placeholder {
                    color: #808080;
                    font-size: 16px;
                    opacity: 1;
                }
            }

            .field .col {
                padding: 0.5rem;
            }

            .field {
                display: grid;
                margin: 10px;
            }

            .field-checkbox {
                line-height: 18px;
                margin: 10px;

                label {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #808080;

                    a {
                        text-decoration: none;
                        color: #1067b8;
                    }
                }
            }

            .button-container {
                display: grid;
                margin: 25px 10px;
            }

            .button-container-sec {
                display: grid;
                margin: 10px;
            }
        }

        .form-container-section {
            height: 70vh;
            display: flex;
            justify-content: center;
            align-items: center;

            .confirm {
                width: 100%;

                .noteMessages {
                    .p-message-wrapper {
                        justify-content: flex-start !important;
                    }
                }
            }

            p {
                font-size: 24px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: left;
                margin-left: 11px;
            }

            .heading-content {
                p {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #808080;
                }
            }

            label {
                font-size: 16px;
                color: #000000;

                ::placeholder {
                    color: #808080;
                    font-size: 16px;
                    opacity: 1;
                }
            }

            .field .col {
                padding: 0.5rem;
            }

            .field {
                display: grid;
                margin: 10px;
            }

            .field-checkbox {
                line-height: 18px;

                label {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #808080;

                    a {
                        text-decoration: none;
                        color: #1067b8;
                    }
                }
            }

            .button-container {
                display: grid;
            }

            .button-container-sec {
                display: grid;
                margin: 10px;
            }
        }
    }

    .p-password {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .p-icon-field {
            width: 100% !important;
        }
    }

    .p-password .p-password-show-icon,
    .p-password .p-password-hide-icon {
        margin-right: 20px !important;
    }
}

.p-input-icon-right .p-inputtext {
    padding-right: 2rem;
    width: 100%;
}

.auth-section .p-card .p-card-body {
    padding: 0rem !important;
}

.auth-section .p-card .p-card-content {
    padding: 0px !important;
}

.error .p-inputtext {
    border: 1px solid #E04A4A !important;
}

.email-error {
    border: 1px solid #E04A4A !important;
}

@media (max-width: 767px) {
    .auth-section {
        .signup-card-section {
            .image-field {
                display: none;
            }

            .image-field-sec {
                display: none;
            }
        }
    }
}

@media (max-width: 500px) {
    .auth-section .p-card .p-card-body {
        padding: 0rem !important;
        box-shadow: none !important;
        border-radius: 0px !important;
    }

    .auth-section {
        .signup-card-section {
            margin: -7px !important;
            box-shadow: none !important;
            border-top: 1px solid #052941B2;
        }
    }

    .auth-section .signup-card-section .form-container .field-checkbox label {
        line-height: 25px !important;
    }
}