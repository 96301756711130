.framework-section {
    text-align: center;
    padding: 2rem;

    h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #151413;
    }

    .subtitle {
        font-size: 22px;
        color: #0A0B0A;
        margin-bottom: 2rem;
    }

    .framework-content {
        display: flex;
        justify-content: center;
        gap: 3rem;

        .framework-item {
            background-color: #f9f9f9;
            border-radius: 10px;
            padding: 1rem;
            width: -webkit-fill-available;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            text-align: left;
            overflow: hidden;

            img {
                width: 100%;
                border-radius: 10px;
                background-size: contain;
                object-fit: cover;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }
            }

            h3 {
                font-size: 22px;
                color: #151413;
                margin: 1rem 0 0.5rem;
            }

            p {
                font-size: 18px;
                color: #433F3E;
            }
        }
    }
}

@media screen and (max-width: 1008px) {
    .framework-section {
        h2 {
            font-size: 30px;
        }

        .subtitle {
            font-size: 20px;
        }

        .framework-content {
            gap: 1rem;

        }
    }
}

@media screen and (max-width: 767px) {
    .framework-section {
        h2 {
            font-size: 28px;
        }

        .framework-content {
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
}

@media screen and (max-width: 490px) {
    .framework-section {
        padding: 2rem;

        h2 {
            font-size: 24px;
            margin: 0;
        }

        .subtitle {
            font-size: 18px;
        }
    }
}