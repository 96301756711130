.chat-bot-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h2 {
    margin: 0;
  }
}

.chatbot-section {
  width: 100%;
  padding: 20px;
  height: 78vh;

  .result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-fill-available;
  }

  .chat-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow-y: auto;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    height: -webkit-fill-available;
    border-radius: 20px;
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }

  .message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .message.user {
    background-color: #f1f1f1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .message.assistant {
    flex-wrap: wrap;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .chat-input-form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;

    .chatbot-input-section {
      display: flex;
      width: -webkit-fill-available;
    }
  }
  .chat-input-form input {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.preview {
  height: 300px;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .text-overlay {
    position: absolute;
    color: white;
    z-index: 2;
    padding: 40px;
    border-radius: 10px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  .bubble {
    padding: 10px;
    border-radius: 10px;
    align-self: flex-start;
    max-width: 70%;
    display: flex;
    align-items: center;
  }

  .bubble img {
    margin-right: 10px;
  }

  .user-message {
    background-color: rgba(255, 255, 255, 0.8);
    margin: 40px;
    border-radius: 10px;
    max-width: 70%;
    float: right;
    width: 70%;
    bottom: 20px;
    position: absolute;
    right: 10px;
    padding: 10px;
  }
}

.p-colorpicker {
  .p-inputtext {
    padding: 1.4rem 5.75rem;
  }
}


.message.assistant.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message{
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #000;
    border-radius: 50%;
    animation: dot-blink 1s infinite;
  }

}

@keyframes dot-blink {
  0%, 20% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0.2;
  }
}

@media (max-width: 778px) {
  .chatbot-section {
    .chat-input-form {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .chatbot-input-section {
        input {
          margin-left: 0px;
        }
      }

      input {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
}

@media (max-width: 490px) {
  .chat-bot-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
