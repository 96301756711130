.business-section {
    padding: 2rem;

    h2 {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #151413;
    }

    .subtitle {
        font-size: 22px;
        color: #0A0B0A;
        margin-bottom: 2rem;
    }

    .business-content {
        display: flex;
        justify-content: center;
        gap: 3rem;

        .business-item {
            background-color: #f9f9f9;
            border-radius: 10px;
            width: -webkit-fill-available;
            text-align: left;
            box-shadow: 1px 4px 4px 0px #0000001A;

            h3 {
                font-size: calc(18px + 3px);
                color: #151413;
                margin: 1rem 0 0.5rem;
            }

            p {
                font-size: 18px;
                color: #433F3E;
                padding: 0 1rem;
                height: 185px;
            }

            .business-header {
                display: flex;
                align-items: center;
                padding: 1rem;
                box-shadow: 1px 4px 4px 0px #0000001A;
                border-radius: 10px 10px 0px 0px;
                gap: 15px;

            }
        }

        a.learn-more {
            font-size: 18px;
            color: #1067B8;
            text-decoration: none;
            text-align: right;
            display: block;
            padding: 10px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 1330px) {
    .business-section .business-content .business-item p {
        height: 216px;
    }
}

@media screen and (max-width: 1220px) {
    .business-section {
        .business-content {
            gap: 1rem;

            .business-item {
                width: -webkit-fill-available;

                .business-header {
                    height: 90px;
                }

                p {
                    height: 264px;
                }
            }
        }
    }
}

@media screen and (max-width: 1008px) {
    .business-section {
        h2 {
            font-size: 30px;
        }

        .subtitle {
            font-size: 20px;
        }

        .business-content {
            gap: 1rem;
            flex-wrap: wrap;

            .business-item p {
                height: -webkit-fill-available;
                min-height: -webkit-fill-available;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .business-section {
        h2 {
            font-size: 28px;
        }

        .business-content {
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
}

@media screen and (max-width: 490px) {
    .business-section {
        padding: 2rem 2rem 0;

        h2 {
            font-size: 24px;
            margin: 0;
        }

        .subtitle {
            font-size: 18px;
        }

        .business-content {
            .business-item {
                width: -webkit-fill-available;

                .business-header {
                    padding: 0.5rem;

                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}