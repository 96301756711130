.workshop-details-container {
    padding: 8rem 4rem;
}

.workshop-details {
    margin: 0rem 6rem;
}

.wd-name {
    margin: 20px 0px 0px 0px;
    color: #000;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wd-description {
    padding: 6px 0px;
    color: #808080;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 23.4px */
}

.wd-date-time {
    padding: 6px 0px;
    color: #737373;
    font-family: Poppins;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 126%;
    /* 22.68px */
}

.share {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 126%;
    /* 22.68px */
    padding: 20px 20px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-name {
    display: flex;
    align-items: center;
}

.wd-pi-user {
    color: white !important;
}

.profile-name {
    color: #000;
}

.header-image {
    width: 100%;
    object-fit: cover;
}

.side-heading {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0px 10px 0px;

}

.workshop-details {
    .ws-topic {
        font-size: 20px;
    }

    .ws-points {
        font-size: 18px;
    }
}

.view-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #220D50;
    color: white;
    border: none;
    cursor: pointer;
}

.share-dropdown {
    position: relative;
    display: flex;
    align-items: center;
}

.dropdown-wdmenu {
    padding: 0px;
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    border-radius: 4px;
    margin-top: 8px;
    margin-left: -154px;
}

.dropdown-wdmenu li {
    font-size: 16px;
    color: #808080;
    padding: 6px 12px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #808080;
}

.dropdown-wdmenu li:hover {
    background-color: #f1f1f1;
}

.social-icon {
    color: black;
    width: 40px;
    height: 40px;
    padding: 3px 10px;
}

.feedback {
    .p-card .p-card-content {
        padding: 0px;
    }
}

.wd-feedback-card {
    .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
        color: #8DAF61;
    }
}

.wd-header-image {
    margin-bottom: 30px;

    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-slide {
        padding: 10px 0px !important;
    }
}

.wd-courosel {
    margin: 1rem 4rem;

    .slick-prev {
        left: -45px;
    }

    .slick-slide {
        padding: 0 5px;
    }

    .slick-image {
        width: 100%;
        height: auto;
        cursor: pointer;
    }

    .slick-prev:before,
    .slick-next:before {
        color: #8DAF61;
    }

}

.image-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: #fff;
    padding: 26px 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.preview-image {
    max-width: 100vh;
    /* Adjust size as needed */
    max-height: 80vh;
    /* Adjust size as needed */
}

.wd-view-more-button {
    font-size: 16px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #fff;
    color: #8DAF61;
    border: 1px solid #8DAF61;
    cursor: pointer;
}

.place-time-details {
    padding: 20px 20px;
}

.place-time-details {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.wd-place {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.wd-social-media {
    margin: 4rem 0rem;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;

    .social-icon {
        width: 45px;
        height: 45px;
        margin: 0px 4px;
        cursor: pointer !important;
    }
}

.guest-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    // filter: grayscale(1) contrast(1.5) brightness(1);
    mix-blend-mode: multiply;
}

.guest-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px
}

.guest-name {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.guest-designation {
    color: #808080;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.guest-description {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bulb {
    width: 20px;
    height: auto;
    object-fit: cover;
}

.workshop-details-container .back-link a {
    text-decoration: none !important;
    display: flex;
    place-items: center;
    color: #3183FF;
    font-size: 20px;
}

@media screen and (max-width:1430px) {
    .workshop-details {
        margin: 0rem 4rem;
    }

    .wd-name {
        font-size: 45px;
    }
}

@media screen and (max-width:1024px) {
    .workshop-details-container {
        padding: 8rem 3rem 2rem !important;
        margin: 0rem !important;
    }

    .workshop-details {
        margin: 3rem !important;
    }

    .wd-name {
        font-size: 35px !important;
    }

    .place-time-details {
        font-size: 18px;
        padding: 0;
    }

    .wd-place {
        font-size: 16px;
    }

    .preview-image {
        max-width: 75vh;
        max-height: 75vh;
    }

    .workshop-details-container {
        margin: 8rem 2rem 3rem;
    }

    .workshop-details {
        .side-heading {
            font-size: 24px
        }

        .ws-topic {
            font-size: 18px;
        }

        .ws-points {
            font-size: 18px;
        }
    }

    .guest-description {
        font-size: 18px;
    }
}

@media screen and (max-width:991px) {
    .workshop-details-container {
        padding: 8rem 2rem 2rem !important;
        margin: 0rem !important;
    }

    .workshop-details {
        margin: 2rem !important;
    }

    .guest-image {
        width: 50%;
    }
}

@media screen and (max-width:768px) {

    .workshop-details {
        margin: 0rem !important;
    }

   
    .wd-name {
        font-size: 30px !important;
    }

    .wd-description {
        font-size: 16px;
    }

    .wd-date-time {
        font-size: 16px;
    }

    .share {
        font-size: 16px;
    }

    .feedback {
        margin: 15px 10px;
    }

    .preview-image {
        max-width: 60vh;
        max-height: 60vh;
        cursor: none;
    }

    .wd-social-media {
        margin: 4rem 0rem 0;
    }
}

@media screen and (max-width:600px) {

    .image-preview-modal {
        display: none;
    }

    .wd-courosel {
        .slick-image {
            cursor: unset;
        }
    }
}

@media screen and (max-width:490px) {

    .workshop-details-container {
        padding: 8rem 1rem 2rem !important;
    }

    .wd-name {
        font-size: 30px !important;
    }

    .guest-image {
        width: 100%;
    }

    .guest-content {
        display: block;
    }
}