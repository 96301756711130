.past-events {
  padding: 20px 60px 40px 60px;
  display: flex;
  flex-direction: column;

  .past-events-header {
    // padding: 1rem 0;
    text-align: center;
    font-size: 32px;
    margin-top: 0;
    // font-weight: 700;
  }

  .past-events-body {
    border: 1px solid #b5b3b3;
    border-radius: 25px;
    box-shadow: 2px 4px 4px 0px #00000040;

    .past-events-body-section {
      display: flex;
      padding: 5rem 2rem;

      .past-event-image {
        width: 25%;
        text-align: center;
        align-content: center;
      }

      .past-event-content {
        display: flex;
        flex-direction: column;
        // row-gap: 20px;
        justify-content: center;
        width: 75%;

        .event-content-title {
          font-size: 24px;
          font-weight: 600;
          width: 90%;
          margin: 0;
        }

        .event-content-desc {
          font-size: 20px;
          font-weight: 400;
          line-height: 1.6;
          // margin: 0;
        }

        .event-content-button {
          display: flex;
          justify-content: flex-end;
          gap: 2rem;

          span {
            cursor: pointer;
            color: blue;
          }
        }
      }
    }
  }

  // .past-events-footer {
  //   padding-top: 2rem;
  //   display: flex;
  //   justify-content: center;
  // }
}

@media (max-width: 1400px) {
  .past-events {
    .past-events-body {
      .past-events-body-section {
        gap: 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .past-events {
    .past-events-body {
      border: 1px solid #b5b3b3;
      border-radius: 25px;

      .past-events-body-section {
        gap: 25px;
        padding: 4rem 2rem;

        .past-event-image {
          width: 30%;

          img {
            height: 230px;
            width: 230px;
          }
        }

        .past-event-content {
          width: 70%;

          .event-content-title {
            font-size: 24px;
          }

          .event-content-desc {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .past-events {
    .past-events-body {
      border: 1px solid #b5b3b3;
      border-radius: 25px;

      .past-events-body-section {
        padding: 2rem;
        flex-direction: column;

        .past-event-image {
          width: 100%;

          img {
            height: 250px;
            width: 250px;
          }
        }

        .past-event-content {
          width: 100%;
          align-items: center;
          padding: 0rem 1rem;

          .event-content-title {
            font-size: 24px;
            text-align: center;
          }

          .event-content-desc {
            font-size: 20px;
            // text-align: center;
          }

          .event-content-button {
            justify-content: center;
            padding: 1rem 0rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .past-events {
    padding: 2rem 2rem;

    .past-events-body {
      .past-events-body-section {
        padding: 2rem 1rem;
      }

      .past-event-content {


        .event-content-title {
          font-size: 24px !important;
        }

        .event-content-desc {
          font-size: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .past-events {
    padding: 2rem 1rem !important;

    .past-events-header {
      padding: 0rem;
    }

    .past-events-body {
      .past-events-body-section {
        gap: 0px;
        padding: 1rem !important;

        .past-event-image {
          img {
            height: 220px;
            width: 220px;
          }
        }

        .past-event-content {
          padding: 0rem;
          padding-top: 2rem !important;
          gap: 15px;

          .event-content-title {
            font-size: 22px !important;
            text-align: center;
          }

          .event-content-desc {
            font-size: 18px !important;
          }

          .event-content-button {
            font-size: 14px;
            gap: 10px;
          }
        }
      }
    }
  }
}