.admin-container {
    max-height: 87vh;
    overflow-y: auto;

    .dashboard-card-section {
        display: flex;
        gap: 20px;
        background-color: transparent;
        padding: 20px;


        .card-details-sec {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .card-icon {
                font-size: 80px;
                color: #220D50;
            }
        }
    }

}

.admin-dialog-section {
    display: grid;
    padding-top: 1.5rem;

    .fields-section {
        display: grid;
        align-items: end;
    }

    .admin-dialog-btn-footer {
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
}