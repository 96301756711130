.settings-container {
  padding: 2rem 3rem;
  max-height: 87vh; // Adjust this value as needed
  overflow-y: auto; // Enable vertical scrolling
  scrollbar-width: none;

  .save-button-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.p-dialog .p-dialog-header {
  background: #220d50 !important;
  color: #ffff;
}

.p-dialog .p-dialog-footer {
    border-top: 1px solid #CCC;
  padding: 1.5rem;
}

.name-id {
  padding-left: 20px;
  align-content: center !important;
}

.info-header {
    border-bottom: 1px solid #CCC;
  margin: 20px 0px;
  display: flex;
  align-items: center;
}

.info-title {
  padding-right: 10px;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit-pencil {
  height: 25px;
  width: 25px;
    color: #8DAF61;
}

.p-password {
  display: inline-grid;
}

.save-button {
  font-size: 16px;
  height: 20%;
}

.avatar-container {
  position: relative;
  width: fit-content;

}

.profile-image {
  width: 6rem;
  height: 6rem;
    background: #EEE;
  border-radius: 50%;
  padding: 12px;
}

.edit-avatar-icon {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
  width: 6rem;
  height: 6rem;
  color: #8ab060;
  cursor: pointer;
  opacity: 0; // Initially hidden
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.avatar-container:hover .edit-avatar-icon {
  opacity: 1; // Show the icon on hover
}

.onhover-edit-icon {
  width: 2rem;
  height: 2rem;
}

.d-profile-section {
  margin: 20px 10px;
}

.stu-name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.stu-id {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1008px) {
  .settings-container {
    padding: 2rem;
  }

  .info-title {
    font-size: 24px;
  }
}



@media (max-width: 490px) {
  .settings-container {
    padding: 1rem;

    .save-button-container{
      Button{
        width: 100%;
        margin: .5rem 0rem;
      }
    }
    .profile-section {
      flex-direction: column;
      align-items: center;
    }

    .personal-info-section, .parent-info-section, .changepswd-info-section  {
      .info-header {
        justify-content: space-between;
        .info-title {
          font-size: 22px;
        }
      }

      .personalInfo-form, .changepwsd-form{
        .grid{
            .field{
                margin-bottom: 0rem !important;
                label{
                    margin-bottom: 0rem !important;
                }
            }
        }
        .changepswd-button {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            margin: .5rem 0rem;
          }
      }
    }
  }
}
