.progress-section {
    padding-top: 97px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
}

.questionnaire {
    margin: 2rem;
    text-align: center;

    h2 {
        font-size: 30px;
        margin-bottom: 0.5rem;
    }

    h3 {
        font-size: 18px;
        margin-top: 2rem;
        color: #83869C;
    }

    p {
        font-size: 22px;
        margin-bottom: 1rem;
    }

    .options {
        display: flex;
        flex-direction: column;
        align-items: center;

        .option {
            border: 2px solid #8DAF61;
            border-radius: 10px;
            background-color: white;
            color: #000;
            padding: 15px;
            margin: 10px;
            width: -webkit-fill-available;
            font-size: 18px;
            max-width: 400px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #8DAF61;
            }

        }

        .other-input {
            .input-text {
                border-bottom: 2px solid #B5B3B3;
                border-top: none;
                border-right: none;
                border-left: none;
                border-radius: 0;
                width: 400px;
            }

            .p-inputtext:enabled:focus {
                box-shadow: none !important;
            }
        }

        .selected {
            background-color: #8DAF61;
            color: white;
            border-color: #8DAF61;
        }
    }

    .navigation {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        button {
            background-color: #8DAF61;
            color: white;
            border: none;
            padding: 10px 20px;
            margin: 0 10px;
            cursor: pointer;
            border-radius: 5px;
            width: 200px;
            display: block;

            &:hover {
                background-color: #8DAF61 !important;
                color: white !important;
            }
        }
    }
}

@media screen and (max-width: 964px) {
    .questionnaire {
        h2 {
            font-size: 26px;
        }

        p {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 490px) {
    .progress-section {
        padding-top: 85px;
    }

    .questionnaire {

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 20px;
        }

        .options {
            .option {
                font-size: 16px;
                max-width: 300px;

            }

            .other-input .input-text {
                width: 300px;
            }
        }
    }
}