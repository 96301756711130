.s2l-our-project-content {
    padding: 95px 60px 30px 60px;

    .our-work-project-container {
        display: block;

        .back-button-project {

            i,
            p {
                font-size: 20px;
                color: #000000;
                font-weight: 400;
            }


        }

        .project-content-main {
            .project-header {
                .project-title-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .project-title-img {
                        padding: 20px 0px;
                        display: flex;
                        flex-direction: row;
                        gap: 2rem;
                        align-items: center;
                        justify-content: flex-start;

                        .project-logo {
                            width: 250px;
                            height: auto;

                            .project-logo {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .project-title {
                            h2 {
                                font-size: 32px;
                                font-weight: 700;
                                line-height: 1.2;
                                color: #433F3E;
                                margin-bottom: 0px;

                                .p-title {
                                    font-weight: 400 !important;
                                }
                            }

                            p {
                                font-size: 20px;
                                line-height: 1.2;
                                color: #433F3E;
                            }
                        }
                    }
                }

                .project-desc {
                    p {
                        font-size: 20px;
                        color: #433F3E;
                        line-height: 1.6;
                    }
                }

                .project-tag-list {
                    .project-tag {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 7px 25px;
                        border-radius: 25px;
                        background-color: #220D50;
                        color: #ffff;
                        font-size: 20px;
                        line-height: 1.6;
                    }

                }
            }

            .project-content {
                .project-menu {
                    position: sticky;
                    top: 100px;
                    height: fit-content;

                    h2 {
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 1.2;

                    }

                    .project-menu-item p {
                        font-size: 20px;
                        line-height: 1.6;
                        color: #433F3E;
                        cursor: pointer;
                        margin-left: 25px;

                        &:hover {
                            border-bottom: 4px solid #8DAF61;
                            transform: scale(1.1);
                            transition: ease-out;
                        }

                        &.active {
                            border-bottom: 4px solid #8DAF61;
                            transform: scale(1.1);

                        }
                    }

                }

                .project-menu-content {
                    h2 {
                        font-size: 32px;
                        font-weight: 700;
                        line-height: 1.2;
                        color: #8DAF61;

                    }

                    p {
                        font-size: 20px;
                        line-height: 1.6;
                        color: #433F3E;
                        margin: 0px;
                    }

                    .project-technologies .technology-list li {
                        font-size: 20px;
                        line-height: 1.6;
                        color: #433F3E;
                    }

                    .project-frameworks li {
                        font-size: 20px;
                        line-height: 1.6;
                        color: #433F3E;
                    }

                    .project-testimonial .testimonial-template {
                        .testimonial-card {
                            width: 30.22%;
                            padding: 30px;
                            margin: 0% 3% 2% 0%;
                            border-radius: 10px;
                            box-shadow: 4px 4px 4px 0px #00000040;

                            h3 {
                                font-size: 24px;
                                font-weight: 700;
                                line-height: 1.4;
                            }

                        }
                    }
                }
            }
        }
    }

    .our-work-project-container-sm {
        display: none;
    }
}

@media screen and (max-width: 1440px) {
    .s2l-our-project-content {
        .our-work-project-container {
            display: block;

            .project-content-main .project-content .project-menu-content .project-testimonial .testimonial-template .testimonial-card {
                width: 48%;
                padding: 20px;
                margin: 0% 2% 5% 0%;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .s2l-our-project-content {
        padding: 110px 40px 20px 40px;

        .our-work-project-container {
            display: block;

            .project-content-main .project-content .project-menu-content .project-testimonial .testimonial-template .testimonial-card {
                width: 48%;
                padding: 20px;
                margin: 0% 2% 5% 0%;
            }
        }
    }
}


@media screen and (max-width:1024px) {
    .s2l-our-project-content {

        .our-work-project-container {
            display: none !important;
        }

        .our-work-project-container-sm {
            display: block !important;

            .project-content-main {
                .project-header {
                    .project-title-header {
                        justify-content: center !important;

                        .project-title-img {
                            flex-direction: column;
                            gap: 1rem;

                            .project-logo {
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;

                                .project-logo {
                                    width: 320px;
                                    height: 100%;
                                }
                            }

                            .project-title {
                                h2 {
                                    font-size: 28px;
                                    font-weight: 700;
                                    line-height: 1.2;
                                    color: #433F3E;
                                    margin-bottom: 0px;
                                    text-align: center;

                                    .p-title {
                                        font-weight: 400 !important;
                                    }
                                }

                                p {
                                    font-size: 18px;
                                    line-height: 1.6;
                                    margin-top: 0px;
                                    padding-top: 5px;
                                    color: #433F3E;
                                    text-align: center;
                                }
                            }
                        }

                        .project-website-btn {
                            display: none;

                        }
                    }

                    .project-desc {
                        p {
                            font-size: 18px;
                            color: #433F3E;
                            line-height: 1.6;
                        }
                    }

                    .project-tag-list {
                        justify-content: center;

                        .project-tag {
                            margin: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 10px 25px;
                            border-radius: 25px;
                            background-color: #220D50;
                            color: #ffff;
                            font-size: 18px;
                            line-height: 1.6;
                        }

                    }
                }

                .project-content {
                    .project-menu-content {
                        .p-accordion-content {
                            padding: 0px !important;
                            border-bottom: none !important;
                        }

                        h2 {
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 1.2;
                            color: #433F3E;

                        }

                        p {
                            font-size: 18px;
                            color: #433F3E;
                            line-height: 1.6;
                        }

                        ul {

                            li {
                                font-size: 18px;
                                line-height: 1.6;
                                color: #433F3E;
                            }
                        }

                        .testimonial-template {

                            .testimonial-card {
                                width: 48%;
                                padding: 20px;
                                margin: 5% 2% 5% 0%;
                                border-radius: 10px;
                                box-shadow: 4px 4px 4px 0px #00000040;

                                h3 {
                                    font-size: 24px;
                                    font-weight: 700;
                                    line-height: 1.4;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}


@media screen and (max-width:768px) {

    .s2l-our-project-content {
        padding: 100px 30px 20px 30px;


    }

}


@media screen and (max-width:520px) {

    .s2l-our-project-content {
        padding: 120px 20px 20px 20px;

        .our-work-project-container-sm .project-content-main {
            .project-header {
                .project-title-header .project-title-img {
                    .project-logo .project-logo {
                        width: 220px;
                        height: 100%;
                    }

                    .project-title h2 {
                        font-size: 26px;
                    }
                }

                .project-tag-list {
                    flex-direction: column !important;
                }
            }

            .project-content .project-menu-content {
                h2 {
                    font-size: 22px;
                }

                ul {
                    padding-left: 25px;
                }

                .testimonial-template .testimonial-card {
                    width: 100%;
                    margin: 5% 0% 5% 0%;

                }
            }
        }

    }

}