@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.p-component {
    font-family: 'Poppins', sans-serif !important;
    color: #052941 !important;
}

.p-button {
    color: #ffffff !important;
    padding: 0.6rem 1rem !important;
    font-size: 20px !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 10px !important;
    font-weight: 500 !important;
    border-radius: 4px !important;
    background: #8DAF61 !important;
    border: none !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}


.bck-btn {
    color: #052941 !important;
    background: white !important;
    border-radius: 4px !important;
    border: 1px solid #000 !important;
}

.button-style-primary {
    border-radius: 25px !important;
    padding: 0.6rem 1.8rem !important;
    overflow: visible !important;

    a {
        text-decoration: none !important;
        color: #FFF !important;
    }
}

.button-style-white {
    border-radius: 25px !important;
    padding: 0.6rem 1.8rem !important;
    background: #ffffff !important;
    color: #000 !important;

    a {
        text-decoration: none;
        color: #1F306E !important;
    }
}

.button-style-white-secondary {
    border-radius: 25px !important;
    padding: 0.6rem 1.8rem !important;
    background: none !important;
    color: #ffffff !important;
    border: 2px solid #ffffff !important;

    a {
        text-decoration: none !important;
        color: #FFF !important;
    }
}

.button-style-primary-square {
    border-radius: 4px !important;
    background: #ffffff !important;

    a {
        color: #8DAF61 !important;
        text-decoration: none;
    }
}

.button-style-primary-square-line {
    border-radius: 4px !important;
    background: #ffffff !important;
    color: #8DAF61 !important;
    border: 2px solid #8DAF61 !important;
    padding: 0.5rem 1rem !important;
}

.button-style-square-line {
    border-radius: 4px !important;
    background: #ffffff !important;
    color: #8DAF61 !important;
    border: 1px solid #8DAF61 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25) !important;

    a {
        color: #052941 !important;
        text-decoration: none;
    }
}

.p-button.p-button-secondary {
    color: #052941 !important;
    border-radius: 10px !important;
    border: 2px solid #000 !important;
    background-color: white !important;
}

.p-button.p-button-secondary:focus {
    border: none !important;
}

.p-button.p-button-info {
    color: #052941 !important;
    border-radius: none !important;
    border: none !important;
    background-color: white !important;
}

.p-button.p-button-help {
    color: #052941 !important;
    border-radius: 0px !important;
    border: 2px solid #352750 !important;
    background-color: white !important;
}

.p-button.p-button-info:focus {
    border: none !important;
}

.section-heading {
    h2 {
        font-size: 38px;
        font-weight: bold;
    }

    p {
        // padding-left: 22px;
        font-size: 22px;
    }

    .border-bottom-3 {
        border-bottom-width: 5px !important;
        width: fit-content;
        color: #352750;
    }
}

.purple-bg {
    background: #220D50 !important;

    a {
        color: #FFFFFF !important;
        text-decoration: none;
    }
}

@media (max-width: 887px) {
    .section-heading {
        h2 {
            font-size: 26px;
        }

        p {
            font-size: 18px;
        }

        .border-bottom-3 {
            border-bottom-width: 3px !important;
        }
    }
}

@media (max-width: 490px) {
    .section-heading h2 {
        font-size: 26px;
    }

    .section-heading p {
        padding-left: 0;
        font-size: 16px;
    }

    .button-style-white {
        padding: 0.6rem 1rem !important;
        font-size: 16px !important;
    }

    .button-style-white-secondary {
        padding: 0.6rem 1rem !important;
        font-size: 16px !important;
    }
}