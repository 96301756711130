.ued-details-container {
    margin-top: 6rem;
}

.ued-banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    padding: 5rem;
}

.banner-left {
    align-content: center;
}

.banner-right {
    align-content: center;
}

.ued-heading {
    color: #FFF;
    font-family: Poppins;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.by {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding: 10px 0;
    /* 23.4px */
}

.ued-desc {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    padding: 10px 75px 10px 0px;
    /* 23.4px */
}

.ued-b-card {
    border-radius: var(--Spacing-ml, 20px);
    background: #FFF;
    color: black;
    padding: 30px;
}

.ued-date-title {
    color: #000;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
}

.ued-date-details {
    color: #808080;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    display: flex;
    justify-content: start;
}

.ued-duration {
    color: #808080;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    display: flex;
    align-items: center;
}

.ued-price {
    color: #000;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
}

.reg-but {
    text-align: center !important;
}

.ued-content {
    margin: 3rem 6rem;
}

.ued-sideheadings {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 0px;
}

.ued-sections {
    padding: 10px 0px;

    .ued-about,
    .ued-topics {
        font-size: 22px;

        .speaker-name {
            color: #352750;
            font-size: 24px;
            margin: 0;
        }
    }
}

.ued-time {
    padding-left: 10px;
}

// .ued-map {
//     .leaflet-container {
//         height: 40vh !important;
//         z-index: -1;
//     }
// }

.ued-location-details {
    padding: 10px 0px;
}

.ued-ls {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.ue-agenda-item {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 26px */
    padding: 20px;
    margin: 20px 0px;
}

.ue-agenda-time {
    font-size: 16px;
    padding: 4px 0px;
}

.ue-agenda-title {
    font-size: 20px;
    padding: 4px 0px;
}

.ue-agenda-details {
    font-size: 18px;
    padding: 4px 0px;
}

.ue-agenda-note {
    font-size: 14px;
    padding: 4px 0px;
}

@media screen and (max-width:1400px) {

    .ued-details-container {
        margin-top: 5rem !important;
    }

    .ued-banner {
        padding: 4rem;
    }

    .ued-heading {
        font-size: 40px;
    }
}

@media screen and (max-width:1024px) {
    .ued-heading {
        font-size: 30px;
    }

    .ued-desc {
        padding: 10px 0px;
    }

    .ued-sections {

        .ued-about,
        .ued-topics {
            font-size: 20px !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .ued-details-container {
        margin-top: 5rem;
    }

    .ued-banner {
        padding: 3rem;
    }

    .ued-heading {
        font-size: 30px;
    }

    .ued-desc {
        padding: 10px 0px;
    }

    .ued-b-card {
        margin: 10px 0px;
    }

    .ued-content {
        margin: 3rem 3rem;
    }
}

@media screen and (max-width: 490px) {

    .ued-details-container {
        margin-top: 4rem;
    }

    .ued-banner {
        padding: 2rem;
    }

    .ued-heading {
        font-size: 26px;
        text-align: center;
    }

    .ued-desc {
        padding: 10px 0px;
    }

    .ued-b-card {
        margin: 10px 0px;
        padding: 20px;
    }

    .ued-content {
        margin: 2rem 2rem;
    }

    .ued-date-details {
        display: inline-block;
        justify-content: start;
    }

    .ued-time {
        padding: 0px !important;
    }

    .ued-sections {

        .ued-about,
        .ued-topics {
            font-size: 18px !important;
        }
    }

    .ued-date-title,
    .ued-price {
        font-size: 22px;
        padding: 0;
    }
}