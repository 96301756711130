.programs-container {
    display: flex;
    flex-direction: column;


    .programs {
        margin-top: 5.8rem;
        // padding: 2rem 4rem;
        position: relative;
        height: 90vh;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;

        .women-banner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -10;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .banner-content {
            // width: 60%;
            color: white;
            padding-left: 4rem;
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 32px;
                font-weight: 600;
                color: #bba6e3;
                margin: 0;
                width: 80%;
            }

            h2 {
                font-size: 60px;
                font-weight: 700;
                margin: 0;
            }

            p {
                font-size: 24px;
                font-weight: 500;
                // margin: 0;
            }

            .button-container {
                padding: 1rem 0;
            }
        }

        .banner-image {
            height: inherit;
            // width: 40%;
            display: flex;
            justify-content: center;
            align-items: end;

            img {
                height: 100%;
                // height: 700px;
                // width: 800px;
            }
        }

        .women-banner-sec {
            display: none;
        }
    }

    .event-and-projects {
        padding: 2rem 2rem;
    }

    .women-png {
        display: none;
    }

    // .programs {
    //     margin-top: 6rem;
    //     height: 90vh;
    //     width: 100%;
    //     background-color: aqua;
    //     background-repeat: no-repeat;
    //     object-fit: cover;
    //     position: relative;

    //     .image-bg {
    //         position: absolute;
    //         top: 0;
    //         bottom: 0;
    //         right: 0;
    //         left: 0;
    //         object-fit: cover;
    //         img {
    //             height: 100%;
    //             width: 100%;
    //         }
    //     }
    // }
}

@media (max-width: 1700px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            height: 80vh;

            .banner-content {
                h2 {
                    font-size: 54px;
                }
            }

            .banner-image {
                height: inherit;
                // width: 40%;
                display: flex;
                justify-content: center;
                align-items: end;

                img {
                    height: 100%;
                    // height: 700px;
                    // width: 650px;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            height: 70vh;

            .banner-content {
                h3 {
                    font-size: 28px;
                }

                h2 {
                    font-size: 44px;
                }
            }

            .banner-image {
                height: inherit;
                // width: 40%;
                display: flex;
                justify-content: center;
                align-items: end;

                img {
                    height: 100%;
                    // height: 700px;
                    // width: 550px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            position: relative;
            // height: 450px;
            height: inherit;
            display: flex;
            align-items: center;
            height: 60vh;

            .banner-content {
                h3 {
                    font-size: 24px;
                }

                h2 {
                    font-size: 36px;
                }

                p {
                    font-size: 20px;
                }

                .button-container {
                    padding: 1rem 0;
                }
            }

            .banner-image {
                img {
                    height: 100%;
                    // width: 480px;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            // height: 400px;
            height: 50vh;
            // height: inherit;

            .banner-content {
                h3 {
                    font-size: 22px;
                }

                h2 {
                    font-size: 36px;
                }

                p {
                    font-size: 18px;
                }
            }

            .banner-image {
                img {
                    height: 100%;
                    // width: 400px;
                }
            }
        }
    }
}

@media (max-width: 920px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            // height: 320px;
            height: 50vh;

            .banner-content {
                padding-left: 3rem;
                h3 {
                    font-size: 20px;
                }

                h2 {
                    font-size: 28px;
                }

                p {
                    font-size: 18px;
                    margin: 0;
                }

                .button-container {
                    height: 40px;
                     
                    .p-button {
                        height: inherit;
                        font-size: 18px;
                    }
                }
            }

            .banner-image {
                img {
                    height: 90%;
                    // width: 320px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .programs-container {
        .programs {
            margin-top: 5rem;
            height: auto;
            justify-content: space-evenly;
            flex-direction: column;

            .banner-content {
                width: 80%;
                padding: 1rem;
                background-color: white;
                border-radius: 10px;
                gap: 10px;
                margin-top: 3rem;


                h3 {
                    font-size: 18px;
                }

                h2 {
                    font-size: 24px;
                    color: #220d50;
                }

                p {
                    font-size: 20px;
                    color: #220d50;
                    font-weight: 700px;
                }

                .button-container {
                    padding: 0rem;
                }
            }

            .banner-image {
                display: none;
            }

            .women-banner-sec {
                display: block;
                // background-color: aqua;
                height: 312px;
                width: 250px;
                display: flex;
                justify-content: start;
                position: relative;

                img {
                    position: absolute;
                    height: 350px;
                    width: 350px;
                    left: -35px;
                }
            }
        }
    }
}

@media (max-width: 490px) {
    .programs-container {
        .programs {
            margin-top: 4rem;
            height: auto;
            justify-content: center;

            .banner-content {
                width: 80%;
                padding: 1rem;
                background-color: white;
                border-radius: 10px;

                h3 {
                    // font-size: 16px;
                    display: none;
                }

                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 18px;
                }

                .button-container {
                    padding-bottom: 0rem !important;
                    padding-top: 0.5rem;
                    height: auto;

                    .p-button {
                        font-size: 18px !important;
                        height: auto;
                    }
                }
            }


            .women-banner-sec {
                display: block;
                // background-color: aqua;
                height: 285px;
                width: 250px;
                display: flex;
                justify-content: start;
                position: relative;

                img {
                    position: absolute;
                    height: 300px;
                    width: 300px;
                    top: 1rem;
                    left: -17px;
                }
            }
        }

        .event-and-projects {
            padding: 2rem 0rem;
        }
    }
}